import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import BehaviorCard from "../components/BehaviorCard";
import Box from "../components/Box";
import Button from "../components/form/Button";
import Heading from "../components/form/Heading";
import { AppContext } from "../contexts/AppContext";
import { BehaviorsContext } from "../contexts/BehaviorsContext";
import MainLayout from "../layouts/MainLayout";
import { BehaviorModel } from "../models/BehaviorModel";
import Api from "../services/Api";

export default function Behaviors() {
  const navigate = useNavigate();
  const [now, setNow] = useState<Date>();

  const { currentPatient, setIsLoading, patients } = useContext(AppContext);
  const { behaviors, fetchBehaviors } = useContext(BehaviorsContext);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    setInterval(() => {
      setNow(new Date());
    }, 1000);
  }, []);

  useEffect(() => {
    if (behaviors) {
      setTimeout(() => {
        setPending(false);
      }, 300);
    }
  }, [behaviors]);

  if (pending)
    return (
      <MainLayout>
        <div className="text-center pt-4">
          <span
            className="spinner-border spinner-border text-secondary"
            role="status"
            aria-hidden="true"
          ></span>
        </div>
      </MainLayout>
    );

  return (
    <>
      <MainLayout>
        {behaviors?.length ? (
          <div className="row gx-2 gy-2 mb-3">
            <NavLink to="/behaviors/records" className="col-6">
              <Button variant="light" fullWidth>
                Storico <i className="fa-solid fa-clipboard-list ms-2"></i>
              </Button>
            </NavLink>
            <NavLink to="/behaviors/reports" className="col-6">
              <Button variant="light" fullWidth>
                Analisi <i className="fa-solid fa-chart-simple ms-2"></i>
              </Button>
            </NavLink>
            <NavLink to="/behaviors/new" className="col-12">
              <Button variant="light" fullWidth>
                Nuovo comportamento{" "}
                <i className="fa-solid fa-circle-plus ms-2"></i>
              </Button>
            </NavLink>
          </div>
        ) : (
          <Box
            className="text-center"
            style={{
              maxWidth: "500px",
              margin: "0px auto",
            }}
          >
            <img
              src="/images/undraw_key_points_re_u.svg"
              alt=""
              style={{
                width: "100%",
                maxWidth: "300px",
                margin: "20px auto",
                display: "block",
              }}
            />
            <h4>Comportamenti da monitorare</h4>
            <p>
              Puoi creare diversi comportamenti da monitorare per ogni soggetto e distinguerli
              grazie ai colori.
              <br/>
              Puoi selezionare un altro soggetto dalla barra in alto.
            </p>
            <NavLink to="/behaviors/new" className="col-12">
              <Button variant="light" fullWidth>
                Nuovo comportamento{" "}
                <i className="fa-solid fa-circle-plus ms-2"></i>
              </Button>
            </NavLink>
          </Box>
        )}

        {behaviors?.map((behavior: BehaviorModel, i) => (
          <BehaviorCard
            key={behavior._id}
            behavior={behavior}
            now={now}
          ></BehaviorCard>
        ))}
      </MainLayout>
    </>
  );
}
