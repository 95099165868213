import styled from "styled-components";

interface TabsButtonProps {
  label: string;
  value: string;
}

interface TabsButtonsProps {
  buttons: TabsButtonProps[];
  value: string;
  onChange: (value: string) => void;
}

const TBContainer = styled.div`
  background-color: #fff;
  overflow: hidden;
  display: flex;

  button {
    height: 45px;
    background-color: transparent;
    border: none;
    flex: 1;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    color: #7c8db5;
    border-radius: 100px;

    &.active {
      color: inherit;
      background-color: #f9fafc;
    }
  }
`;

const TabsButtons = ({ buttons, value, onChange }: TabsButtonsProps) => {
  return (
    <>
      <TBContainer>
        {buttons.map((button) => (
          <button
            key={button.value}
            className={button.value === value ? "active" : ""}
            onClick={() => {
              onChange(button.value);
            }}
          >
            {button.label}
          </button>
        ))}
      </TBContainer>
    </>
  );
};

export default TabsButtons;
