export const sexOptions = [
  {
    value: "male",
    label: "Maschio",
  },
  {
    value: "female",
    label: "Femmina",
  },
];
