import styled from "styled-components";

const Alert = styled.div<{ fullHeight: boolean | null }>`
  background-color: #f1f3f9;
  border-radius: 6px;
  padding: 16px;
  color: #7c8db5;
  height: ${(props) => (props.fullHeight ? "100%" : "auto")};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Alert;
