import moment from "moment";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import BehaviorCard from "../components/BehaviorCard";
import Box from "../components/Box";
import Button from "../components/form/Button";
import ColorPicker from "../components/form/ColorPicker";
import Heading from "../components/form/Heading";
import Input from "../components/form/Input";
import Select from "../components/form/Select";
import SimpleIconButton from "../components/SimpleIconButton";
import { AppContext } from "../contexts/AppContext";
import MainLayout from "../layouts/MainLayout";
import { BehaviorModel } from "../models/BehaviorModel";
import { PatientModel } from "../models/PatientModel";
import Api from "../services/Api";
import { sexOptions } from "../services/GlobalVariables";

interface UpsertPatientProps {
  isTheFirstPatient?: boolean;
}

export default function UpsertPatient({
  isTheFirstPatient,
}: UpsertPatientProps) {
  const { state } = useLocation();
  const {
    currentPatient,
    fetchPatients,
    setShowPatientsModal,
    setCurrentPatient,
  } = useContext(AppContext);
  const patientData: PatientModel = state?.patient;
  const isNew = patientData ? false : true;
  const [pending, setPending] = useState<boolean>(false);

  const [firstName, setFirstName] = useState<string>(patientData?.firstName);
  const [sex, setSex] = useState<string>(patientData?.sex);
  const [birthday, setBirthday] = useState<Date | null>(
    patientData?.birthday || null
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (patientData) {
      setFirstName(patientData.firstName);
      setSex(patientData.sex);
      setBirthday(patientData.birthday);
    }
  }, [patientData]);

  function checkErrors() {
    if (!firstName) return toast.error("Inserisci il nome del soggetto");
    if (!sex) return toast.error("Seleziona il sesso");
    if (!birthday) return toast.error("Seleziona la data di nascita");
  }

  async function createPatient() {
    if (checkErrors()) return;
    setPending(true);
    const newPatient = await Api.createPatient({
      firstName: firstName,
      sex: sex,
      birthday: birthday,
    } as PatientModel);
    if (newPatient) {
      toast.success("Creato!");
      await fetchPatients(newPatient._id); // Set it as current
      navigate("/");
    } else {
      toast.error("Errore");
    }
    setPending(false);
  }

  async function updatePatient() {
    if (checkErrors()) return;
    setPending(true);
    const isUpdated = await Api.updatePatient({
      _id: patientData._id,
      firstName: firstName,
      sex: sex,
      birthday: birthday,
    } as PatientModel);
    if (isUpdated === true) {
      if (currentPatient?._id === patientData._id) {
        fetchPatients(patientData._id); // Update the current
      } else {
        fetchPatients();
      }
      toast.success("Aggiornato!");
      navigate(-1);
    } else {
      toast.error("Errore");
    }
    setPending(false);
  }

  async function deletePatient() {
    setPending(true);
    if (window.confirm("Vuoi eliminare questo soggetto?")) {
      const isDeleted = await Api.deletePatient(patientData._id!);
      if (isDeleted === true) {
        if (currentPatient?._id === patientData._id) {
          setCurrentPatient(undefined);
        }
        fetchPatients();
        toast.success("Rimosso!");
        navigate(-1);
      } else {
        toast.error("Errore");
      }
    }
    setPending(false);
  }

  return (
    <>
      <MainLayout
        title={isNew ? "Nuovo" : "Modifica"}
        onBack={() => {
          isNew && setShowPatientsModal(true);
        }}
        simpleHeader={isTheFirstPatient}
      >
        <Box>
          {isTheFirstPatient && (
            <>
              <img
                src="/images/undraw_work_in_progress_re_byic.svg"
                alt=""
                style={{
                  width: "100%",
                  maxWidth: "300px",
                  margin: "20px auto",
                  display: "block",
                }}
              />
              <h4>Soggetto da monitorare</h4>
              <p>
                Compila i seguenti campi relativi al soggetto da monitorare.
                Inserisci i tuoi dati se vuoi monitorare te stesso.
              </p>
            </>
          )}
          {!isNew && (
            <div className="d-flex justify-content-between mb-2">
              <h4>{patientData.firstName}</h4>

              <SimpleIconButton
                color="red"
                onClick={() => {
                  if (!pending) {
                    deletePatient();
                  }
                }}
              >
                <i
                  className="fa-solid fa-trash"
                  style={{ color: "#dc0000" }}
                ></i>
              </SimpleIconButton>
            </div>
          )}
          <div className="row gy-2">
            <div className="col-12">
              <Input
                label="Nome"
                type="text"
                value={firstName}
                placeholder="Nome"
                onChange={(e) => {
                  setFirstName(e.currentTarget.value);
                }}
              ></Input>
            </div>
            <div className="col-12">
              <Input
                label="Data di nascita"
                type="date"
                value={moment(birthday).format("yyyy-MM-DD")}
                placeholder="Data di nascita"
                onChange={(e) => {
                  setBirthday(moment(e.currentTarget.value).toDate());
                }}
              ></Input>
            </div>
            <div className="col-12">
              <Select
                label="Sesso"
                placeholder="Seleziona"
                value={sex}
                onChange={(e) => {
                  setSex(e.currentTarget.value);
                }}
                options={sexOptions}
              />
            </div>

            <div className="col-12">
              {isNew ? (
                <Button
                  loading={pending}
                  onClick={() => {
                    createPatient();
                  }}
                  fullWidth
                >
                  Crea
                </Button>
              ) : (
                <Button
                  loading={pending}
                  fullWidth
                  onClick={() => {
                    updatePatient();
                  }}
                >
                  Salva
                </Button>
              )}
            </div>
          </div>
        </Box>
      </MainLayout>
    </>
  );
}
