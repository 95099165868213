import React, { ChangeEvent } from "react";
import styled from "styled-components";
import InputLabel from "./InputLabel";

interface InputProps {
  label?: string;
  value: string | number;
  placeholder?: string;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  disable?: boolean;
  options: { value: string | number; label: string }[];
}

const SelectStyle = styled.select`
  display: block;
  padding: 10px;
  background-color: #f6f9fa;
  border: 1px solid #e1e3ea;
  width: 100%;
  border-radius: 6px;
  box-sizing: border-box;
  outline: none;
  transition: border 0.2s, box-shadow 0.2s;
  box-shadow: 0px 0px 0px 0px #5e2ce114;
  color: #0c0d48;
  appearance: none;

  ::placeholder {
    color: #7c8db5;
  }

  ::-webkit-date-and-time-value {
    text-align: left;
  }

  &:focus {
    box-shadow: 0px 0px 0px 4px #14c3bc21;
    outline: none;
    border: 1px solid #14c3bc4d;
  }

  &:disabled {
    border-color: transparent;
  }

  display: block;
  width: 100%;
  //}
`;

const SelectWrapperStyle = styled.div`
  position: relative;

  select {
    padding-right: 35px;
  }

  i {
    position: absolute;
    bottom: 15px;
    right: 15px;
  }
`;

const Select = ({
  label,
  value,
  placeholder,
  onChange,
  disable = false,
  options,
}: InputProps) => {
  return (
    <SelectWrapperStyle>
      {label && <InputLabel className="text">{label}</InputLabel>}
      <SelectStyle
        value={value ?? ""}
        //placeholder={placeholder ? placeholder : label ? label : ""}
        onChange={(e) => {
          onChange(e);
        }}
        disabled={disable}
      >
        {placeholder ? (
          <option value={""} disabled>
            {placeholder}
          </option>
        ) : (
          <></>
        )}
        {options.map((option) => (
          <option value={option.value}>{option.label}</option>
        ))}
      </SelectStyle>
      <i className="fa-solid fa-caret-down"></i>
    </SelectWrapperStyle>
  );
};

export default Select;
