import React, { useContext, useEffect, useState } from "react";
import { NavLink, redirect, useNavigate } from "react-router-dom";
import styled from "styled-components";
import BehaviorCard from "../components/BehaviorCard";
import Box from "../components/Box";
import Button from "../components/form/Button";
import Heading from "../components/form/Heading";
import Input from "../components/form/Input";
import MainLayout from "../layouts/MainLayout";
import { BehaviorModel } from "../models/BehaviorModel";
import { AccountModel } from "../models/AccountModel";
import Api from "../services/Api";
import { AppContext } from "../contexts/AppContext";

const PatientRow = styled.div`
  background-color: #f1f3f9;
  padding: 16px 14px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  cursor: pointer;
`;

export default function Account() {
  const navigate = useNavigate();
  const {
    account,
    currentPatient,
    resetAppContext,
    setShowPatientsModal,
    logout,
  } = useContext(AppContext);
  const [name, setFirstName] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  useEffect(() => {
    if (!account) return;
    setFirstName(account.firstName);
    setEmail(account.email);
  }, [account]);

  async function deleteMyAccount() {
    if (window.confirm("Vuoi eliminare definitivamente il tuo account?")) {
      resetAppContext();
      localStorage.removeItem("accessToken");
      navigate("/login");
    }
  }

  return (
    <>
      <MainLayout title="Account">
        <Box>
          <h5 className="mb-3">Soggetto corrente</h5>
          <PatientRow
            onClick={() => {
              navigate("/patients/edit", {
                state: {
                  patient: currentPatient,
                },
              });
              setShowPatientsModal(false);
            }}
          >
            <i
              className="fa-solid fa-circle-user me-2"
              style={{ fontSize: "20px" }}
            ></i>
            <span className="flex-grow-1">{currentPatient?.firstName}</span>
            <i className="fa-solid fa-pen-to-square"></i>
          </PatientRow>
        </Box>

        <Box>
          <h5>Informazioni account</h5>
          <div className="row gy-2">
            <div className="col-12">
              <Input
                label="Nome"
                type="text"
                value={name}
                placeholder=""
                onChange={(e) => {
                  setFirstName(e.currentTarget.value);
                }}
                disable
              ></Input>
            </div>
            <div className="col-12">
              <Input
                label="Email"
                type="email"
                value={email}
                placeholder=""
                onChange={(e) => {
                  setEmail(e.currentTarget.value);
                }}
                disable
              ></Input>
            </div>
            {/* <div className="col-12">
              <Button fullWidth onClick={() => {}}>
                Salva
              </Button>
            </div> */}
          </div>
        </Box>

        <Box>
          <Button
            fullWidth
            onClick={() => {
              logout();
            }}
          >
            Esci<i className="icofont-logout ms-2"></i>
          </Button>
        </Box>
      </MainLayout>
    </>
  );
}
