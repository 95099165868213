import React, { ChangeEvent } from "react";
import styled from "styled-components";
import InputLabel from "./InputLabel";
interface InputProps {
  label?: string;
  type: "text" | "password" | "number" | "date" | "time" | "email";
  value: string | number | Date;
  placeholder?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  selectOnFocus?: boolean;
  disable?: boolean;
  required?: boolean;
  autoComplete?: string;
}

const InputStyle = styled.input`
  /* input[type="text"],
  input[type="number"],
  input[type="date"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="password"],
  textarea,
  select { */
  display: block;
  padding: 10px;
  background-color: #f6f9fa;
  border: 1px solid #e1e3ea;
  width: 100%;
  border-radius: 6px;
  box-sizing: border-box;
  outline: none;
  transition: border 0.2s, box-shadow 0.2s;
  box-shadow: 0px 0px 0px 0px #5e2ce114;
  color: #0c0d48;
  appearance: none;

  ::placeholder {
    color: #7c8db5;
  }

  ::-webkit-date-and-time-value {
    text-align: left;
  }

  &:focus {
    box-shadow: 0px 0px 0px 4px #14c3bc21;
    outline: none;
    border: 1px solid #14c3bc4d;
  }

  &:disabled {
    border-color: transparent;
  }

  display: block;
  width: 100%;
  //}
`;

const Input = ({
  label,
  type,
  value,
  placeholder,
  onChange,
  selectOnFocus,
  disable = false,
  required = false,
  autoComplete,
}: InputProps) => {
  return (
    <>
      {label && <InputLabel className="text">{label}</InputLabel>}
      <InputStyle
        type={type}
        pattern={type === "number" ? "\\d*" : undefined}
        value={value?.toString()}
        placeholder={placeholder ? placeholder : label ? label : ""}
        onChange={(e) => {
          onChange(e);
        }}
        onFocus={(e) => {
          if (selectOnFocus) e.currentTarget.select();
        }}
        disabled={disable}
        required={required}
      ></InputStyle>
    </>
  );
};

export default Input;
