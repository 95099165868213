import moment from "moment";
import styled from "styled-components";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import BehaviorCard from "../components/BehaviorCard";
import Button from "../components/form/Button";
import Heading from "../components/form/Heading";
import Input from "../components/form/Input";
import MainLayout from "../layouts/MainLayout";
import { BehaviorModel } from "../models/BehaviorModel";
import { BehaviorRecordModel } from "../models/BehaviorRecordModel";
import Api from "../services/Api";
import { mockData } from "../services/mockDataChart";
import MultipleButtons from "../components/form/MultipleButtons";
import Box from "../components/Box";
import Alert from "../components/Alert";
import TabsButtons from "../components/form/TabsButtons";
import { BehaviorsContext } from "../contexts/BehaviorsContext";
import { DateRange, DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { AccountModel } from "../models/AccountModel";
import { AppContext } from "../contexts/AppContext";
import DateController from "../components/DateController";
import Select from "../components/form/Select";
import BehaviorsLegend from "../components/BehaviorsLegend";

interface BehaviorWithRecords {
  _id: BehaviorModel["_id"];
  name: BehaviorModel["name"];
  color: BehaviorModel["color"];
  records: BehaviorRecordModel[];
}

interface SquareChartDivProps {
  cols: number;
}

const SquareChartDiv = styled.div<SquareChartDivProps>`
  display: grid;
  grid-template-columns: auto repeat(${({ cols }) => cols}, 1fr);
  gap: 3px;
  //background-color: whitesmoke;
  overflow: scroll;
  padding-bottom: 16px; // scroll fix
  margin-bottom: -16px; // scroll fixed

  .ax-row {
    display: flex;
    flex-direction: row;
  }

  .ax-cell {
    background-color: white;
  }

  .ax-cell-header {
    color: #7b8db5;
    font-size: 10px;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
  }

  .ax-cell-header-vert {
    position: sticky;
    left: 0;
    background-color: #fff;
    z-index: 1;
    text-align: right;
    padding: 4px;

    &:before {
      content: "";
      background-color: white;
      height: 100%;
      width: 100%;
      position: absolute;
      display: block;
      left: -16px;
      top: 0;
      z-index: -1;
    }
  }

  .last-col {
    position: sticky;
    right: 0;
    background-color: #fff;
    z-index: 1;

    &:before {
      content: "";
      background-color: white;
      height: 100%;
      width: 100%;
      position: absolute;
      display: block;
      right: 3px;
      top: 0;
      z-index: -1;
    }
  }

  .cell {
    background-color: #f9fafc;
    height: 28px;
    min-width: 28px;
    width: 100%;
    overflow: hidden;
    border-radius: 3px;
    font-size: 9px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 14px;
    font-weight: 500;
    color: white;
  }
`;

const SquareChart = styled.table`
  th {
    font-size: 12px;
    font-weight: 500;
    color: #7b8db5;
  }

  thead > th:first-child,
  tr > td:first-child {
    color: #7b8db5;
    font-size: 12px;
    font-weight: 500;
    text-align: right;
    padding-right: 5px;

    position: sticky;
    left: 0;
    background-color: #fff;
    z-index: 1;

    &:before {
      content: "";
      background-color: white;
      height: 100%;
      width: 100%;
      position: absolute;
      display: block;
      left: -16px;
      top: 0;
      z-index: -1;
    }
  }

  .cell {
    background-color: #f1f3f9;
    height: 24px;
    width: 24px;
    overflow: hidden;
    border-radius: 3px;
    font-size: 9px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 12px;
    font-weight: 500;
    color: white;
  }
`;

export default function BehaviorReports() {
  const { state } = useLocation();
  const { account, accounts } = useContext(AppContext);
  const { behaviors, fetchBehaviors } = useContext(BehaviorsContext);
  const [filteredBehaviors, setFilteredBehaviors] = useState<BehaviorModel[]>();
  const [visibleBehaviors, setVisibleBehaviors] = useState<BehaviorModel[]>();
  const [currentAccountId, setCurrentAccountId] = useState<
    string | undefined
  >();

  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [formattedGridData, setFormattedGridData] = useState<any[]>([]);
  const [intervalInMinutes, setIntervalInMinutes] = useState<number>(60 * 6);
  const [variable, setVariable] = useState<string>("frequency");
  const [view, setView] = useState<string>("weekly");

  const gridRowIntervals = [600, 1200, 1800, 2400];
  const gridRowIntervalsLabels = ["Notte", "Mattina", "Pomeriggio", "Sera"];
  const [gridThList, setGridThList] = useState<string[]>([]);
  const [maxGridValues, setMaxGridValues] = useState<any>({});

  const [startDDDD, setStartDDDD] = useState<Date>();
  const [endDDDD, setEndDDDD] = useState<Date>();

  const [activePayload, setActivePayload] = useState<any[]>();
  const [behaviorRecords, setBehaviorRecords] = useState<BehaviorRecordModel[]>(
    []
  );

  const [activeCellIndexBar, setActiveCellIndexBar] = useState<any>();

  async function fetchBehaviorsAndRecords() {
    if (!behaviors.length) {
      fetchBehaviors();
    }
    const behaviorRecords = await Api.getBehaviorRecords(
      undefined,
      currentAccountId
    );
    if (behaviorRecords) {
      setBehaviorRecords(behaviorRecords?.reverse() || []);
    }
  }

  useEffect(() => {
    fetchBehaviorsAndRecords();
  }, [currentAccountId]);

  useEffect(() => {
    const fBehaviors: BehaviorModel[] =
      account?.role === "professionist" && currentAccountId
        ? behaviors.filter(
            (behavior) => behavior.account_ids?.indexOf(currentAccountId) !== -1
          )
        : behaviors;
    setFilteredBehaviors(fBehaviors);
    if (fBehaviors.length) setVisibleBehaviors([fBehaviors[0]]);
    else setVisibleBehaviors([]);
  }, [behaviors, currentAccountId]);

  useEffect(() => {
    if (
      visibleBehaviors &&
      visibleBehaviors.length &&
      behaviorRecords.length &&
      intervalInMinutes &&
      startDDDD &&
      endDDDD
    ) {
      console.info(" ---- RECHART RENDER ----");

      let records = behaviorRecords.filter(
        (rec: any) =>
          moment(rec.datetime).isAfter(startDDDD) &&
          moment(rec.datetime).isBefore(endDDDD)
      );

      // reset
      setFormattedData([]);
      setFormattedGridData([]);
      setGridThList([]);

      if (!records.length) {
        return;
      }

      var chartData: any = [];
      var gridData: any = [];

      const dayNoTime = moment(startDDDD).startOf("day");

      const X =
        view === "daily"
          ? 24
          : view === "weekly"
          ? 24 * 7
          : view === "monthly"
          ? 24 * moment(startDDDD).daysInMonth()
          : 0;
      const numberOfIntervals = Math.round((X * 60) / intervalInMinutes);
      const colIntervals: string[] = new Array(numberOfIntervals)
        .fill(0)
        .map((_) => dayNoTime.add(intervalInMinutes, "minutes").toISOString());

      let empty: any = {};
      visibleBehaviors?.forEach((beh: any) => {
        empty[beh._id] = {
          frequency: 0,
          intensity: 0,
          duration: 0,
          totalIntensity: 0,
          totalDuration: 0,
        };
      });

      let curRecIndex = 0;
      colIntervals.forEach((interval, i) => {
        chartData[i] = {
          interval: interval,
          ...JSON.parse(JSON.stringify(empty)),
        };

        while (curRecIndex < records.length) {
          let rec = records[curRecIndex];
          /*
          Se la data del record appartiene a questo intervallo, faccio le somme.
          Se non appartiene, interrompo il ciclo dei records per poi riprenderlo
          al prossimo intervallo.
          */
          if (moment(rec.datetime).isBefore(interval)) {
            if (chartData[i][rec.behavior_id]) {
              chartData[i][rec.behavior_id].frequency++;
              chartData[i][rec.behavior_id].totalIntensity +=
                rec.intensity || 0;
              chartData[i][rec.behavior_id].totalDuration += rec.duration || 0;
              chartData[i][rec.behavior_id]["interval"] = interval;
              // Generate the average (It will be overwrite everytime, but at least it doesn't have to be looped again)
              chartData[i][rec.behavior_id].intensity = Math.round(
                chartData[i][rec.behavior_id].totalIntensity /
                  chartData[i][rec.behavior_id].frequency
              );
              chartData[i][rec.behavior_id].duration = Math.round(
                chartData[i][rec.behavior_id].totalDuration /
                  chartData[i][rec.behavior_id].frequency
              );
            }
            curRecIndex++;
          } else {
            break;
          }
        }
      });

      setGridThList(colIntervals);
      let maxValues: any = {};
      records.forEach((rec) => {
        gridRowIntervals.forEach((interv, intervalIndex) => {
          if (!gridData[intervalIndex])
            gridData[intervalIndex] = new Array(colIntervals.length).fill(0);
          if (
            Number(moment(rec.datetime).format("Hmm")) >
              (gridRowIntervals[intervalIndex - 1] || 0) &&
            Number(moment(rec.datetime).format("Hmm")) < interv
          ) {
            colIntervals.forEach((head, headIndex) => {
              if (!gridData[intervalIndex][headIndex])
                gridData[intervalIndex][headIndex] = {};
              if (
                moment(rec.datetime).isAfter(
                  moment(head).subtract(1, "days")
                ) &&
                moment(rec.datetime).isBefore(moment(head))
              ) {
                if (!gridData[intervalIndex][headIndex][rec.behavior_id])
                  gridData[intervalIndex][headIndex][rec.behavior_id] = {
                    frequency: 0,
                  };
                gridData[intervalIndex][headIndex][rec.behavior_id][
                  "frequency"
                ]++;

                // Calc the max value
                if (!maxValues[rec.behavior_id]) maxValues[rec.behavior_id] = 0;
                if (
                  gridData[intervalIndex][headIndex][rec.behavior_id][
                    "frequency"
                  ] > maxValues[rec.behavior_id]
                )
                  maxValues[rec.behavior_id] =
                    gridData[intervalIndex][headIndex][rec.behavior_id][
                      "frequency"
                    ];
                return;
              }
            });

            // gridData[intervalIndex][colIntervals.length][rec.behavior_id]={
            //   frequency: rowSum,
            // };

            return;
          }
        });
      });

      //gridData.push();
      var colTotals: any[] = Array.from(
        { length: gridData[0].length },
        () => ({})
      );
      gridData.forEach((row: any, rowIndex: number) => {
        let rowTotals: any = {};
        row.forEach((col: any, colIndex: number) => {
          Object.entries(col).forEach(([id, { frequency }]: any) => {
            if (rowTotals[id]) {
              rowTotals[id]["frequency"] += frequency;
            } else {
              rowTotals[id] = { frequency: frequency };
            }

            if (colTotals[colIndex][id]) {
              colTotals[colIndex][id]["frequency"] += frequency;
            } else {
              colTotals[colIndex][id] = { frequency: frequency };
            }
          });
        });
        gridData[rowIndex].push(rowTotals);
      });
      gridData.push(colTotals);

      setMaxGridValues(maxValues);
      setFormattedGridData(gridData);
      setFormattedData(chartData);
    }
  }, [
    visibleBehaviors,
    behaviorRecords,
    intervalInMinutes,
    startDDDD,
    endDDDD,
    currentAccountId,
  ]);

  useEffect(() => {
    if (view === "weekly" || view === "monthly") {
      setIntervalInMinutes(60 * 24);
    } else {
      setIntervalInMinutes(60 * 6);
    }
  }, [view]);

  function getAxiesTickLabel(t: string) {
    if (!t) return "";
    switch (view) {
      case "daily":
        if (!formattedData.length) return t;
        if (intervalInMinutes !== 60 * 6) {
          return moment(t).format("h:mm");
        } else {
          let momentDate = moment(formattedData[0].interval).startOf("day"); // To avoid errors, keep the date of the first interval
          if (moment(t).isSameOrBefore(momentDate.clone().add(6, "hours"))) {
            return "Notte";
          } else if (
            moment(t).isSameOrBefore(momentDate.clone().add(12, "hours"))
          ) {
            return "Mattina";
          } else if (
            moment(t).isSameOrBefore(momentDate.clone().add(18, "hours"))
          ) {
            return "Pomeriggio";
          } else {
            return "Sera";
          }
        }
      case "weekly":
        return moment(t).subtract(1, "days").format("ddd D");
      case "monthly":
        return moment(t).subtract(1, "days").format("D");
      default:
        return t;
    }
  }

  const renderEdgeTick = (tickProps: any) => {
    const { x, y, payload } = tickProps;
    const { offset } = payload;
    const pathX = Math.floor(x - offset) + 0.5;

    if (pathX === 5.5) {
      return <></>;
    }

    return (
      <path d={`M${pathX},${y - 18}v${-20}`} stroke="#666666" opacity="0.1" />
    );
  };

  // function renderTooltip(index: number) {
  //   let behIndex: number = behaviorShownIndexes.indexOf(index);
  //   return activePayload && activePayload[behIndex] ? (
  //     <LegendChipTooltip>{activePayload[behIndex].value}</LegendChipTooltip>
  //   ) : (
  //     <></>
  //   );
  // }

  const renderCustomizedLabel = (props: any) => {
    const { x, y, width, height, value } = props;
    const radius = 10;

    return (
      <g>
        <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" />
        <text
          x={x + width / 2}
          y={y - radius}
          fill="#fff"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {value?.split(" ")[1]}
        </text>
      </g>
    );
  };

  const AccountsSelectBox = () => {
    if (account?.role !== "professionist") return <></>;
    return (
      <Box>
        <Select
          value={currentAccountId!}
          onChange={(e) => {
            setCurrentAccountId(e.currentTarget.value);
          }}
          options={[
            {
              value: "",
              label: "Tutti gli account",
            },
            ...accounts.map((account) => {
              return {
                value: account._id,
                label: account.firstName,
              };
            }),
          ]}
        ></Select>
      </Box>
    );
  };

  return (
    <>
      <MainLayout title="Analisi">
        <AccountsSelectBox />
        <Box>
          <BehaviorsLegend
            filteredBehaviors={filteredBehaviors || []}
            visibleBehaviors={visibleBehaviors || []}
            onChange={(visibleBehaviors) => {
              setVisibleBehaviors([...visibleBehaviors]);
              setActivePayload([]); // reset
            }}
          />
        </Box>
        <Box
        // style={{
        //   position: "sticky",
        //   top: "20px",
        //   zIndex: 9,
        //   boxShadow: "0px 0px 20px #00000012",
        // }}s
        >
          <DateController
            onDone={(view, startD, endD) => {
              setView(view);
              setStartDDDD(startD);
              setEndDDDD(endD);
            }}
            view={view}
          />
        </Box>
        <Box>
          <TabsButtons
            value={variable}
            onChange={(value) => {
              setVariable(value);
            }}
            buttons={[
              { value: "frequency", label: "Frequenza" },
              { value: "intensity", label: "Intensità" },
              { value: "duration", label: "Durata" },
            ]}
          ></TabsButtons>
          <div
            style={{
              overflow: "hidden",
              overflowX: "scroll",
            }}
            className="mt-3"
          >
            <div
              style={{
                height: "230px",
                minWidth: view === "monthly" ? `${31 * 18}px` : ``,
              }}
            >
              {formattedData.length ? (
                <ResponsiveContainer width="100%" height="100%">
                  {view !== "monthly" ? (
                    <BarChart
                      data={formattedData}
                      onMouseMove={(data, index) => {
                        if (data) {
                          setActivePayload(data.activePayload);
                          setActiveCellIndexBar(data.activeTooltipIndex);
                        }
                      }}
                      onMouseLeave={() => {
                        setActivePayload([]);
                        setActiveCellIndexBar(null);
                      }}
                      //data.activeTooltipIndex //https://recharts.org/en-US/examples/BarChartWithCustomizedEvent
                      barCategoryGap={3}
                      barGap={2}
                    >
                      <CartesianGrid vertical={false} strokeOpacity="0.2" />
                      <XAxis
                        dataKey="interval"
                        tickFormatter={(t) => getAxiesTickLabel(t)}
                        tickMargin={2}
                        tickLine={false}
                        tick={{ fontSize: 10, color: "#7C8DB5" }}
                        stroke="#7C8DB5" // text
                        axisLine={{ stroke: "#fff" }}
                      />
                      <YAxis
                        tickMargin={2}
                        tickLine={false}
                        tick={{ fontSize: 10, color: "#7C8DB5" }}
                        stroke="#7C8DB5" // text
                        axisLine={{ stroke: "#fff" }}
                        width={25}
                      />
                      {/* <XAxis
                        dataKey="interval"
                        axisLine={false}
                        tickLine={false}
                        interval={0}
                        tick={renderEdgeTick}
                        height={1}
                        scale="band"
                        xAxisId="quarter"
                      /> */}
                      {visibleBehaviors?.map((behavior) => (
                        <Bar
                          key={"bar-" + behavior._id}
                          dataKey={`${behavior._id}.${variable}`}
                          //fill={`${behaviors[behIndex].color}`}
                          name={behavior._id}
                          radius={[5, 5, 5, 5]}
                        >
                          {activeCellIndexBar != null ? (
                            <LabelList
                              fill="#fff"
                              fontSize={12}
                              dataKey={`${behavior._id}.${variable}`}
                              //content={renderCustomizedLabel}
                            />
                          ) : (
                            <></>
                          )}
                          {formattedData.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={`${behavior.color}`}
                              opacity={
                                activeCellIndexBar == null ||
                                index === activeCellIndexBar
                                  ? 1
                                  : 0.5
                              }
                            ></Cell>
                          ))}
                        </Bar>
                      ))}
                    </BarChart>
                  ) : (
                    <LineChart
                      data={formattedData}
                      onClick={(data) => {
                        setActivePayload(data.activePayload);
                      }}
                    >
                      <CartesianGrid vertical={false} strokeOpacity="0.2" />
                      <XAxis
                        dataKey="interval"
                        tickFormatter={(t) => getAxiesTickLabel(t)}
                        tickMargin={2}
                        tickLine={false}
                        tick={{ fontSize: 10, color: "#7C8DB5" }}
                        stroke="#7C8DB5" // text
                        axisLine={{ stroke: "#fff" }}
                      />

                      <YAxis
                        tickMargin={2}
                        tickLine={false}
                        tick={{ fontSize: 10, color: "#7C8DB5" }}
                        stroke="#7C8DB5" // text
                        axisLine={{ stroke: "#fff" }}
                        interval={0}
                      />
                      {/* 
                      <Tooltip />
                      <Legend /> */}
                      {visibleBehaviors?.map((behavior) => (
                        <Line
                          key={"line" + behavior._id}
                          type="monotone"
                          dataKey={`${behavior._id}.${variable}`}
                          stroke={`${behavior.color}`}
                          fill={`${behavior.color}`}
                          strokeWidth={1}
                          activeDot={{ r: 8 }}
                          name={behavior._id}
                        />

                        // <Bar
                        //   key={"beh" + behIndex}
                        //   dataKey={`${behaviors[behIndex]._id}.${variable}`}
                        //   fill={`${behaviors[behIndex].color}`}
                        //   name={behaviors[behIndex]._id}
                        //   radius={[5, 5, 5, 5]}
                        // />
                      ))}
                    </LineChart>
                  )}
                </ResponsiveContainer>
              ) : (
                <Alert fullHeight>Nessun dato disponibile</Alert>
              )}
            </div>
          </div>
        </Box>
        {view !== "daily" && formattedGridData?.length ? (
          <>
            {visibleBehaviors?.map((b) => {
              return (
                <Box key={b._id + "-sg"}>
                  <h6 className="mb-3 text-center">{b.name}</h6>
                  <SquareChartDiv cols={gridThList.length + 1}>
                    <div className="ax-cell ax-cell-header ax-cell-header-vert --start"></div>
                    {gridThList.map((h) => (
                      <div
                        key={b._id + h + "-sg-gth"}
                        className="ax-cell ax-cell-header"
                      >
                        {getAxiesTickLabel(h)}
                      </div>
                    ))}
                    <div className="ax-cell ax-cell-header last-col"></div>

                    {formattedGridData.map((row, rowIndex: number) => (
                      <>
                        <div className="ax-cell ax-cell-header ax-cell-header-vert --start">
                          {gridRowIntervalsLabels[rowIndex]}
                        </div>
                        {row.map((col: any, colIndex: number) => {
                          const intensityLevels = [0.25, 0.5, 0.75, 1];
                          const maxIntensity = intensityLevels.length;

                          const intensity =
                            col[b._id] && col[b._id].frequency
                              ? Math.ceil(
                                  (col[b._id].frequency /
                                    maxGridValues[b._id]) *
                                    maxIntensity
                                )
                              : 0.1;
                          let opacity = intensityLevels[intensity - 1] || 0.1;

                          return (
                            <div
                              key={b._id + colIndex + "-sg-cl"}
                              className={`ax-cell ${
                                colIndex === row.length - 1 ? "last-col" : ""
                              }`}
                            >
                              <span
                                className="cell"
                                style={
                                  rowIndex === formattedGridData.length - 1 ||
                                  colIndex === row.length - 1
                                    ? {
                                        color: "#7a8db5",
                                      }
                                    : {
                                        opacity: opacity?.toFixed(1) ?? 1,
                                        backgroundColor:
                                          opacity != null && opacity > 0
                                            ? b.color
                                            : "#f9fafc",
                                      }
                                }
                              >
                                {col[b._id!]?.frequency}
                              </span>
                            </div>
                          );
                        })}
                      </>
                    ))}
                  </SquareChartDiv>
                </Box>
              );
            })}
          </>
        ) : (
          <></>
        )}
      </MainLayout>
    </>
  );
}
