import styled from "styled-components";

const SimpleIconButton = styled.span`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
`;

export default SimpleIconButton;
