import React, { Suspense, useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import BehaviorCard from "../components/BehaviorCard";
import Box from "../components/Box";
import Button from "../components/form/Button";
import Checkbox from "../components/form/Checkbox";
import ColorPicker from "../components/form/ColorPicker";
import Heading from "../components/form/Heading";
import Input from "../components/form/Input";
import SimpleIconButton from "../components/SimpleIconButton";
import { AppContext } from "../contexts/AppContext";
import { BehaviorsContext } from "../contexts/BehaviorsContext";
import MainLayout from "../layouts/MainLayout";
import { BehaviorModel } from "../models/BehaviorModel";
import Api from "../services/Api";

export default function UpsertBehavior() {
  const { state } = useLocation();
  const { currentPatient } = useContext(AppContext);
  const { fetchBehaviors } = useContext(BehaviorsContext);
  const behaviorData = state?.behavior;
  const isNew = behaviorData ? false : true;
  const [pending, setPending] = useState<boolean>(false);

  const [name, setName] = useState<BehaviorModel["name"]>(behaviorData?.name);
  const [color, setColor] = useState<BehaviorModel["color"]>(
    behaviorData?.color
  );
  const [trackDurationAndIntensity, setTrackDurationAndIntensity] =
    useState<boolean>(
      behaviorData?.trackDuration ?? behaviorData?.trackIntensity
    );

  const navigate = useNavigate();

  useEffect(() => {}, []);

  function checkErrors() {
    if (!currentPatient) return;
    if (!name) return toast.error("Inserisci il nome del comportamento");
    if (!color) return toast.error("Seleziona un colore");
  }

  async function createBehavior() {
    if (checkErrors()) return;
    setPending(true);
    const isCreated = await Api.createBehavior({
      patient_id: currentPatient!._id!,
      name: name,
      color: color,
      trackDuration: trackDurationAndIntensity,
      trackIntensity: trackDurationAndIntensity,
    });
    if (isCreated === true) {
      fetchBehaviors();
      toast.success("Creato!");
      navigate(-1);
    } else {
      toast.error("Errore");
    }
    setPending(false);
  }

  async function updateBehavior() {
    if (checkErrors()) return;
    setPending(true);
    const isUpdated = await Api.updateBehavior({
      _id: behaviorData._id,
      patient_id: currentPatient!._id!,
      name: name,
      color: color,
      trackDuration: trackDurationAndIntensity,
      trackIntensity: trackDurationAndIntensity,
    });
    if (isUpdated === true) {
      fetchBehaviors();
      toast.success("Aggiornato!");
      navigate(-1);
    } else {
      toast.error("Errore");
    }
    setPending(false);
  }

  async function deleteBehavior() {
    setPending(true);
    if (window.confirm("Vuoi eliminare questo comportamento?")) {
      const isDeleted = await Api.deleteBehavior(behaviorData._id);
      if (isDeleted === true) {
        fetchBehaviors();
        toast.success("Rimosso!");
        navigate(-1);
      } else {
        toast.error("Errore");
      }
    }
    setPending(false);
  }

  return (
    <>
      <MainLayout title={isNew ? "Nuovo" : "Modifica"}>
        <Box>
          <div className="d-flex justify-content-between mb-2">
            <h4>{!isNew ? behaviorData.name : "Nuovo comportamento"}</h4>
            {!isNew ? (
              <SimpleIconButton
                //loading={pending}
                // backgroundColor="#ff000026"
                color="red"
                //iconClass="icofont-ui-bin"
                onClick={() => {
                  if (!pending) {
                    deleteBehavior();
                  }
                }}
              >
                <i
                  className="fa-solid fa-trash"
                  style={{ color: "#dc0000" }}
                ></i>
              </SimpleIconButton>
            ) : (
              <></>
            )}
          </div>
          <div className="row gy-3">
            <div className="col-12">
              <Input
                label="Nome comportamento"
                type="text"
                value={name}
                placeholder="Nome comportamento"
                onChange={(e) => {
                  setName(e.currentTarget.value);
                }}
              ></Input>
            </div>
            <div className="col-12">
              <ColorPicker
                label="Colore etichetta"
                value={color}
                onChange={(color) => {
                  setColor(color);
                }}
              />
            </div>

            <div className="col-12">
              <Checkbox
                checked={trackDurationAndIntensity}
                onChange={() => {
                  setTrackDurationAndIntensity(!trackDurationAndIntensity);
                }}
              >
                Traccia la durata e l'intensità
              </Checkbox>
            </div>

            <div className="col-12">
              {isNew ? (
                <Button
                  loading={pending}
                  onClick={() => {
                    createBehavior();
                  }}
                  fullWidth
                >
                  Crea
                </Button>
              ) : (
                <Button
                  loading={pending}
                  fullWidth
                  onClick={() => {
                    updateBehavior();
                  }}
                >
                  Salva
                </Button>
              )}
            </div>
          </div>
        </Box>
      </MainLayout>
    </>
  );
}
