import moment from "moment";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import BehaviorCard from "../components/BehaviorCard";
import BehaviorsLegend from "../components/BehaviorsLegend";
import Box from "../components/Box";
import Button from "../components/form/Button";
import Heading from "../components/form/Heading";
import Input from "../components/form/Input";
import InputLabel from "../components/form/InputLabel";
import Select from "../components/form/Select";
import { AppContext } from "../contexts/AppContext";
import { BehaviorsContext } from "../contexts/BehaviorsContext";
import MainLayout from "../layouts/MainLayout";
import { AccountModel } from "../models/AccountModel";
import { BehaviorModel } from "../models/BehaviorModel";
import { BehaviorRecordModel } from "../models/BehaviorRecordModel";
import Api from "../services/Api";
import { mockData } from "../services/mockDataChart";

const IntensityBar = styled.span<{ value: number; color: string }>`
  display: block;
  height: 6px;
  width: 100%;
  position: relative;
  background-color: #0000000d;
  overflow: hidden;
  border-radius: 100px;
  max-width: 50px;
  margin: 0 auto;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${(props) => props.color ?? "black"};
    height: 100%;
    width: ${(props) => (props.value ? `calc(100%/10*${props.value})` : 0)};
  }
`;

export default function BehaviorHistory() {
  const navigate = useNavigate();
  const { account, accounts } = useContext(AppContext);
  const { behaviors } = useContext(BehaviorsContext);
  const [filteredBehaviors, setFilteredBehaviors] = useState<BehaviorModel[]>();
  const [visibleBehaviors, setVisibleBehaviors] = useState<BehaviorModel[]>();
  const [pending, setPending] = useState<boolean>(true);
  const [behaviorRecords, setBehaviorRecords] = useState<BehaviorRecordModel[]>(
    []
  );
  const [currentAccountId, setCurrentAccountId] = useState<
    string | undefined
  >();
  const [currentBehaviorId, setCurrentBehaviorId] = useState<
    string | undefined
  >();

  const [objBehaviors, setObjBehaviors] = useState<any>();

  function renderDayPartIcon(hour: number, behavior_id: string) {
    let htmlClass = "";
    if (hour < 6) {
      htmlClass = "icofont-moon";
    } else if (hour < 12) {
      htmlClass = "icofont-sun-rise";
    } else if (hour < 18) {
      htmlClass = "icofont-sun-alt";
    } else if (hour < 24) {
      htmlClass = "icofont-sun-set";
    }
    return (
      <i
        className={htmlClass}
        style={{
          fontSize: "16px",
          backgroundColor: objBehaviors[behavior_id]?.color ?? "black",
          padding: "4px",
          color: "#fff",
          borderRadius: "6px",
        }}
      ></i>
    );
  }

  useEffect(() => {
    if (behaviors) {
      const obj = behaviors.reduce<{
        [key: number]: BehaviorModel;
      }>((acc, cur) => {
        return { ...acc, [cur._id]: cur };
      }, {});
      setObjBehaviors(obj);
    }
  }, [behaviors]);

  async function fetchBehaviorRecords() {
    setPending(true);
    const behaviorRecords = await Api.getBehaviorRecords(
      currentBehaviorId,
      currentAccountId
    );
    if (behaviorRecords) {
      setBehaviorRecords(behaviorRecords);
    }
    setPending(false);
  }

  useEffect(() => {
    fetchBehaviorRecords();
  }, [currentAccountId, currentBehaviorId]);

  useEffect(() => {
    const fBehaviors: BehaviorModel[] =
      account?.role === "professionist" && currentAccountId
        ? behaviors.filter(
            (behavior) => behavior.account_ids?.indexOf(currentAccountId) !== -1
          )
        : behaviors;
    setFilteredBehaviors(fBehaviors);
    setVisibleBehaviors([]);
  }, [behaviors, currentAccountId]);

  const AccountsSelectBox = () => {
    if (account?.role !== "professionist") return <></>;
    return (
      <div className="mb-2">
        <Select
          value={currentAccountId!}
          onChange={(e) => {
            setCurrentAccountId(e.currentTarget.value);
          }}
          options={[
            {
              value: "",
              label: "Tutti gli account",
            },
            ...accounts.map((account) => {
              return {
                value: account._id,
                label: account.firstName,
              };
            }),
          ]}
        ></Select>
      </div>
    );
  };

  return (
    <>
      <MainLayout title="Storico">
        <Box>
          <AccountsSelectBox />
          {/* <Select
            value={currentBehaviorId!}
            onChange={(e) => {
              setCurrentBehaviorId(e.currentTarget.value);
            }}
            options={[
              {
                value: "",
                label: "Tutti i comportamenti",
              },
              ...behaviors.map((behavior) => {
                return {
                  value: behavior._id!,
                  label: behavior.name,
                };
              }),
            ]}
          ></Select> */}
          <BehaviorsLegend
            filteredBehaviors={filteredBehaviors || []}
            visibleBehaviors={visibleBehaviors || []}
            onChange={(vBehaviors) => {
              if (vBehaviors?.length) {
                const lastB = vBehaviors[vBehaviors.length - 1];
                setVisibleBehaviors([lastB]);
                setCurrentBehaviorId(lastB._id);
              } else {
                setVisibleBehaviors([]);
                setCurrentBehaviorId(undefined);
              }
            }}
            singleChoice={true}
          />
        </Box>
        <Box>
          {pending ? (
            <div className="text-center">
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </div>
          ) : (
            <table className="basic-table text-center">
              <thead>
                <tr>
                  <th className="text-center"></th>
                  <th className="text-center">
                    <InputLabel>Data</InputLabel>
                  </th>
                  <th className="text-center">
                    <InputLabel>Ora</InputLabel>
                  </th>
                  <th className="text-center">
                    <InputLabel>Intensità</InputLabel>
                  </th>
                  <th className="text-center">
                    <InputLabel>Durata</InputLabel>
                  </th>
                </tr>
              </thead>
              <tbody>
                {behaviorRecords
                  .slice(0, 100)
                  .map((rec: BehaviorRecordModel) => (
                    <tr
                      key={rec._id + rec.datetime}
                      onClick={() => {
                        if (!objBehaviors[rec.behavior_id]) return;
                        navigate("/behaviors/records/edit", {
                          state: {
                            behavior: objBehaviors[rec.behavior_id],
                            record: rec,
                          },
                        });
                      }}
                    >
                      <td style={{ whiteSpace: "nowrap" }}>
                        {renderDayPartIcon(
                          Number(moment(rec.datetime).format("H")),
                          rec.behavior_id
                        )}
                      </td>
                      <td>{moment(rec.datetime).format("L")} </td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {moment(rec.datetime).format("HH:mm")}
                      </td>
                      {typeof rec.intensity !== "undefined" &&
                      typeof rec.duration !== "undefined" ? (
                        <>
                          <td>
                            <b style={{ fontSize: "10px" }}>
                              {rec.intensity.toString() + "/10"}
                            </b>
                            <IntensityBar
                              value={rec.intensity}
                              color={"#14c3bc"}
                            />
                          </td>
                          <td>{rec.duration.toString()}s</td>
                        </>
                      ) : (
                        // <td colSpan={2}>
                        //   <i
                        //     className="icofont-flash"
                        //     style={{ fontSize: "25px", color: "#ffb400" }}
                        //   />
                        // </td>
                        <>
                          <td>-</td>
                          <td>-</td>
                        </>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </Box>
      </MainLayout>
    </>
  );
}
