import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

interface ButtonProps {
  onClick?: () => void;
  children: any;
  fullWidth?: boolean;
  variant?: "primary" | "secondary" | "light";
  size?: "default" | "small";
  className?: string;
  color?: string;
  backgroundColor?: string;
  iconClass?: string;
  loading?: boolean;
  disabled?: boolean;
  submit?: boolean;
}

const StyledButton = styled.button`
  background-color: #15c3bc;
  padding: 2px 14px;
  min-height: 50px;
  min-width: 50px;
  border-radius: 6px;
  border: none;
  color: #fff;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 600;
  transition: transform 0.3s;

  &:not(:disabled) {
    &:hover {
      background-color: #12d9d3;
    }

    &:active {
      transform: scale(0.98);
    }
  }

  &:disabled {
    opacity: 0.6;
  }

  &.light {
    background-color: #e5e9f5;
    color: #0c0d48;

    &:not(:disabled):hover {
      background-color: #dde1ed;
    }
  }

  i {
    font-size: 20px;
    vertical-align: middle;
    margin-top: -3px;
    display: inline-block;
  }

  &.small {
    min-height: 32px;
    font-size: 14px;
  }
`;

const Button = ({
  children,
  onClick,
  fullWidth = false,
  variant = "primary",
  size = "default",
  className,
  color,
  backgroundColor,
  iconClass,
  loading = false,
  disabled = false,
  submit = false,
}: ButtonProps) => {
  return (
    <StyledButton
      style={{ backgroundColor: backgroundColor, color: color }}
      className={`${fullWidth ? "w-100" : ""} ${variant ?? ""} ${size ?? ""} ${
        className ?? ""
      }`}
      onClick={onClick}
      disabled={disabled || loading}
      type={submit ? "submit" : "button"}
    >
      {!loading ? (
        <>
          {iconClass ? <i className={"me-2 " + iconClass}></i> : <></>}
          <span>{children}</span>
        </>
      ) : (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      )}
    </StyledButton>
  );
};

export default Button;
