import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";
import { BehaviorModel } from "../models/BehaviorModel";

const BehaviorsLegendStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -10px;
`;

// const LegendChipTooltip = styled.div`
//   background-color: #f9fbfc;
//   border-radius: 6px;
//   padding: 4px 10px;
//   font-weight: 600;
//   font-size: 16px;
// `;

const LegendChip = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  justify-content: space-between;
  user-select: none;

  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 100px;
  padding-left: 10px;
  padding-right: 16px;
  background-color: #fff;
  border-radius: 100px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f1f3f9;
  }

  label {
    flex: 1;
    font-weight: 500;
    cursor: pointer;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
  }

  span {
    background-color: black;
    border-radius: 6px;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin-right: 8px;

    display: flex;
    align-items: center;
    justify-content: center;

    i {
      color: #fff;
      display: block;
      font-size: 12px;
    }
  }

  &:not(.active) {
    opacity: 0.8;
    span {
      opacity: 0.3;
      i {
        display: none;
      }
    }
  }
`;

interface BehaviorsLegendProps {
  filteredBehaviors: BehaviorModel[];
  visibleBehaviors: BehaviorModel[];
  onChange: (visibleBehaviors: BehaviorModel[]) => void;
  singleChoice?: boolean;
}

export default function BehaviorsLegend({
  filteredBehaviors,
  visibleBehaviors,
  onChange,
  singleChoice = false,
}: BehaviorsLegendProps) {
  return (
    <BehaviorsLegendStyled>
      {filteredBehaviors.map((behavior) => {
        let index: number = visibleBehaviors.findIndex(
          (b) => b._id === behavior._id
        );
        return (
          <LegendChip
            key={behavior._id}
            onClick={() => {
              if (index !== -1) {
                visibleBehaviors.splice(index, 1);
              } else {
                visibleBehaviors.push(behavior);
              }

              onChange(visibleBehaviors);
            }}
            className={index !== -1 ? "active" : ""}
          >
            <span
              style={{
                backgroundColor: behavior.color,
                borderRadius: singleChoice ? 100 : undefined,
              }}
            >
              <i className="fa-solid fa-check"></i>
              {/* <i className="fa-regular fa-circle-dot"></i> */}
            </span>
            <label>{behavior.name}</label>
          </LegendChip>
        );
      })}
    </BehaviorsLegendStyled>
  );
}
