import moment from "moment";

export function calculateAge(dateOfBirth: Date | string | undefined): string {
  if (!dateOfBirth) return "";
  const now = moment();
  const birthDate = moment(dateOfBirth);
  const years = now.diff(birthDate, "years");
  return years.toString();
}

export function renderSexIcon(sex: string) {
  switch (sex) {
    case "male":
      return <i className="fa-solid fa-mars" style={{ color: "#006aff" }}></i>;
    case "female":
      return <i className="fa-solid fa-venus" style={{ color: "#fc00ff" }}></i>;
    default:
      return <i className="fa-solid fa-genderless"></i>;
  }
}
