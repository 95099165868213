import React from "react";
import styled from "styled-components";

const MainLoadingStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  background-color: #0c0d48; //#F1F3F9;
`;

export const DotsLoadingStyled = styled.div`
  display: flex;

  div {
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 50%;
    margin-left: 15px;

    animation: move 1s ease-in-out infinite alternate;
  }

  div:nth-child(1) {
    animation-delay: -0.4s;
  }

  div:nth-child(2) {
    animation-delay: -0.2s;
  }

  @keyframes move {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(100%);
    }
  }
`;
const MainLoading = () => {
  return (
    <MainLoadingStyled>
      <DotsLoadingStyled>
        <div></div>
        <div></div>
        <div></div>
      </DotsLoadingStyled>
    </MainLoadingStyled>
  );
};

export default MainLoading;
