import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { DayPicker } from "react-day-picker";
import { toast } from "react-hot-toast";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AppContext } from "../contexts/AppContext";
import { BehaviorsContext } from "../contexts/BehaviorsContext";
import { BehaviorModel } from "../models/BehaviorModel";
import { BehaviorRecordModel } from "../models/BehaviorRecordModel";
import Api from "../services/Api";
import Button from "./form/Button";
import MultipleButtons from "./form/MultipleButtons";

interface DateControllerProps {
  onDone: (view: any, startD: Date, endD: Date) => void;
  view: string;
}

const DateControllerStyled = styled.div`
  background-color: #f9fafc;
  color: #0c0d48;
  text-align: center;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  overflow: hidden;
  justify-content: space-between;
  font-weight: bold;
  cursor: pointer;

  button {
    background-color: transparent;
    border: none;
    color: #0c0d48;
    height: 100%;
    width: 50px;
    cursor: pointer;
    font-weight: bold;

    &:hover {
      background-color: #f1f3f9;
    }
  }

  & > div {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #f1f3f9;
    }
  }
`;

const DayPickerModalDiv = styled.div`
  border: 1px solid black;
  border-radius: 4px;
  background-color: #00000091;
  z-index: 99999999999999999999999999999999999999999999;
  position: fixed;
  left: 0px;
  top: 0;
  width: 100vw;
  height: 100vh;
  /* left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    border-radius: 4px;
    background-color: #fff;
    border-radius: 4px;
    padding: 14px;
  }

  .rdp {
    margin: 10px 0px;
    .rdp-day_selected,
    .rdp-day_selected:focus-visible,
    .rdp-day_selected:hover {
      background-color: #f1f3f9;
      color: #0c0d48;
      font-weight: 500;
    }
  }
`;

const DateController = ({ onDone, view }: DateControllerProps) => {
  const [startDDDD, setStartDDDD] = useState<Date | null>(null);
  const [endDDDD, setEndDDDD] = useState<Date | null>(null);
  const [selectedView, setSelectedView] = useState<string>(view);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [showDayPicker, setShowDayPicker] = useState<boolean>();

  useEffect(() => {
    if (selectedDate) {
      getDateRange(selectedDate, null);
    }
  }, []);

  function getDateRange(date: Date | null, view: string | null) {
    let start;
    let end;
    switch (view || selectedView) {
      case "daily":
        start = moment(date || selectedDate)
          .clone()
          .startOf("day")
          .toDate();
        end = moment(date || selectedDate)
          .clone()
          .endOf("day")
          .toDate();
        break;
      case "weekly":
        start = moment(date || selectedDate)
          .clone()
          .startOf("week")
          .toDate();
        end = moment(date || selectedDate)
          .clone()
          .endOf("week")
          .toDate();
        break;
      case "monthly":
        start = moment(date || selectedDate)
          .clone()
          .startOf("month")
          .toDate();
        end = moment(date || selectedDate)
          .clone()
          .endOf("month")
          .toDate();
        break;
      default:
        return;
    }
    setStartDDDD(start);
    setEndDDDD(end);
    if (date) {
      setSelectedDate(date);
      onDone(selectedView, start, end);
      setShowDayPicker(false);
    }
    if (view) {
      setSelectedView(view);
    }
  }

  function previousSlot() {
    getDateRange(moment(startDDDD).subtract(1, "days").toDate(), null);
  }

  function nextSlot() {
    getDateRange(moment(endDDDD).add(1, "days").toDate(), null);
  }

  function renderDate() {
    let dateString: string = "";
    switch (selectedView) {
      case "daily":
        dateString = moment(selectedDate).format("DD MMM YYYY");
        break;
      case "weekly":
        dateString = `${moment(startDDDD).format("DD MMM")} - ${moment(
          endDDDD
        ).format("DD MMM YYYY")}`;
        break;
      case "monthly":
        dateString = `${moment(startDDDD).format("MMMM YYYY")}`;
        break;
      default:
        break;
    }

    return dateString;
  }

  return (
    <div className="row gy-2">
      {/* 
            <Input
              value={moment(selectedDate).format("yyyy-MM-DD")}
              type="date"
              onChange={(e) => {
                setSelectedDate(moment(e.currentTarget.value).toDate());
              }}
            ></Input> */}

      <div className="col-12">
        <DateControllerStyled>
          <button onClick={previousSlot}>
            <i className="icofont-simple-left"></i>
          </button>
          <div
            onClick={() => {
              setShowDayPicker(true);
            }}
          >
            {renderDate()}
          </div>
          <button onClick={nextSlot}>
            <i className="icofont-simple-right"></i>
          </button>
        </DateControllerStyled>
      </div>

      {showDayPicker ? (
        <DayPickerModalDiv
          onClick={(e) => {
            if (e.target !== e.currentTarget) return;
            setShowDayPicker(false);
          }}
        >
          <div style={{ backgroundColor: "#fff" }}>
            <MultipleButtons
              value={selectedView}
              onChange={(value) => {
                getDateRange(null, value);
              }}
              buttons={[
                { value: "daily", label: "G" },
                { value: "weekly", label: "S" },
                { value: "monthly", label: "M" },
              ]}
            ></MultipleButtons>
            <DayPicker
              showOutsideDays
              mode="range"
              defaultMonth={selectedDate}
              toDate={new Date()}
              selected={{
                from: startDDDD!,
                to: endDDDD!,
              }}
              onDayClick={(val) => {
                if (val) {
                  getDateRange(val, null);
                }
              }}
            />
          </div>
        </DayPickerModalDiv>
      ) : (
        <></>
      )}

      {/* {view === "daily" ? (
              <select
                value={intervalInMinutes}
                onChange={(e) => {
                  setIntervalInMinutes(Number(e.currentTarget.value));
                }}
              >
                <option value={15}>15 minuti</option>
                <option value={30}>30 minuti</option>
                <option value={60}>60 minuti</option>
                <option value={90}>90 minuti</option>
                <option value={120}>120 minuti</option>
                <option value={60 * 6}>6 ore</option>
                <option value={60 * 24} hidden>
                  24 ore
                </option>
              </select>
            ) : (
              <></>
            )} */}
    </div>
  );
};

export default DateController;
