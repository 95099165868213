import React, { ChangeEvent } from "react";
import styled from "styled-components";
import InputLabel from "./InputLabel";
interface InputProps {
  label?: string;
  value: number;
  onChange: (event: number) => void;
}

const InputRangeBtnStyle = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid #e1e3ea;
  background-color: #f6f9fa;

  button {
    flex: 1;
    border: none;
    background-color: transparent;
    font-weight: 500;
    height: 45px;
    margin: 0px;
    padding: 0px;
    color: #7c8db5;

    &.active {
      color: #fff;
      background-color: #15c3bc;

      & ~ button {
      }
    }
  }
`;

const InputRangeBtn = ({ label, value, onChange }: InputProps) => {
  return (
    <>
      {label && <InputLabel className="text">{label}</InputLabel>}
      <InputRangeBtnStyle>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((el) => (
          <button
          key={`input-range-${new Date().getTime()}-${el}`}
            onClick={() => {
              onChange(el);
            }}
            className={el === value ? "active" : ""}
          >
            {el}
          </button>
        ))}
      </InputRangeBtnStyle>
    </>
  );
};

export default InputRangeBtn;
