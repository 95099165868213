import styled from "styled-components";

const InputLabel = styled.div`
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
  color: #7c8db5;
`;

export default InputLabel;
