import { createContext } from "react";
import { BehaviorModel } from "../models/BehaviorModel";

export interface BehaviorsContextInterface {
  activeRecords: any[];
  setActiveRecords: Function;
  behaviors: BehaviorModel[];
  fetchBehaviors: Function;
}

export const BehaviorsContext = createContext<BehaviorsContextInterface>(
  {} as BehaviorsContextInterface
);
