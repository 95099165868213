import styled from "styled-components";
import { behaviorColorList } from "../../models/BehaviorModel";
import InputLabel from "./InputLabel";

interface ColorPickerProps {
  label: string;
  value: string;
  colors?: string[];
  onChange: (value: string) => void;
}

const ColorList = styled.div`
  button {
    display: inline-block;
    height: 45px;
    width: 45px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    border-radius: 100px;
    margin-right: 8px;
    margin-bottom: 8px;
    position: relative;
    vertical-align: middle; // required
    transition: transform 0.3s;

    i {
      display: none;
      color: #fff;
      font-size: 20px;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -10px;
      margin-top: -10px;
    }

    &.active {
      transform: scale(0.9);

      i {
        display: block;
      }
    }
  }
`;

const ColorPicker = ({
  label,
  value,
  colors = behaviorColorList,
  onChange,
}: ColorPickerProps) => {
  return (
    <>
      {label && <InputLabel className="text">{label}</InputLabel>}
      <ColorList>
        {colors.map((color) => (
          <button
            key={color}
            className={
              value && color.toLocaleLowerCase() === value.toLocaleLowerCase()
                ? "active"
                : ""
            }
            onClick={() => {
              onChange(color);
            }}
            style={{ backgroundColor: color }}
          >
            <i className="fa-solid fa-check"></i>
          </button>
        ))}
      </ColorList>
    </>
  );
};

export default ColorPicker;
