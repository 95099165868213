import React, { createContext, useEffect, useState } from "react";
import logo from "./logo.svg";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Api from "./services/Api";
import MainLoading from "./components/MainLoading";
import Login from "./pages/Login";
import Behaviors from "./pages/Behaviors";
import { AppContext } from "./contexts/AppContext";
import UpsertBehavior from "./pages/UpsertBehavior";
import UpsertBehaviorRecord from "./pages/UpsertBehaviorRecord";
import BehaviorHistory from "./pages/BehaviorHistory";
import BehaviorReports from "./pages/BehaviorReports";
import GlobalStyle from "./GlobalStyle";
import Account from "./pages/Account";
import { BehaviorsContext } from "./contexts/BehaviorsContext";
import { PatientModel } from "./models/PatientModel";
import { AccountModel } from "./models/AccountModel";
import { Toaster } from "react-hot-toast";
import Patients from "./pages/Patients";
import UpsertPatient from "./pages/UpsertPatient";
import { BehaviorModel } from "./models/BehaviorModel";
import Signup from "./pages/Signup";
import "moment/locale/it";
import moment from "moment";
import SignupSuccess from "./pages/SignupSuccess";
import ResetPassword from "./pages/ResetPassword";
moment.locale("it");

function App() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLogged, setIsLogged] = useState<boolean>(false);
  const [account, setAccount] = useState<AccountModel>();
  const [behaviorActiveRecords, setBehaviorActiveRecords] = useState<[]>([]);
  const [patients, setPatients] = useState<PatientModel[]>([]);
  const [showPatientsModal, setShowPatientsModal] = useState<boolean>(false);
  const [currentPatient, setCurrentPatient] = useState<PatientModel>();
  const [behaviors, setBehaviors] = useState<BehaviorModel[]>([]);
  const [accounts, setAccounts] = useState<AccountModel[]>([]);

  async function preload() {
    try {
      const account = await Api.getAccount();
      if (account) {
        setAccount(account);
      }
      const patients = await Api.getPatients();
      if (patients) {
        if (patients.length === 1) {
          setCurrentPatient(patients[0]);
        }
        setPatients(patients);
        console.log(patients);
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  function resetAppContext() {
    setAccount(undefined);
    setBehaviors([]);
    setAccounts([]);
    setBehaviors([]);
    setCurrentPatient(undefined);
    setCurrentPatient(undefined);
    setPatients([]);
  }

  async function fetchBehaviors() {
    const behaviors = await Api.getBehaviors();
    if (behaviors) {
      setBehaviors(behaviors);
    }
  }

  async function fetchPatients(patientId?: string) {
    const patients = await Api.getPatients();
    if (patients) {
      if (patients?.length) {
        if (!currentPatient && patients.length === 1) {
          setCurrentPatient(patients[0]);
          setShowPatientsModal(false);
        }
        if (patientId) {
          setCurrentPatient(patients.find((p) => p._id === patientId));
          setShowPatientsModal(false);
        }
        setPatients(patients);
      } else {
        setPatients([]);
      }
    }
  }

  async function fetchAccounts() {
    const accounts = await Api.getAccounts();
    if (accounts) {
      setAccounts(accounts);
    }
  }

  useEffect(() => {
    preload();

    // TODO call authenticated apis.
    // If it's not logged, the interceptor will redirect the user to the login

    const bActiveRecords = JSON.parse(
      localStorage.getItem("behaviorActiveRecords") ?? "[]"
    );
    setBehaviorActiveRecords(bActiveRecords);
  }, []);

  useEffect(() => {
    if (currentPatient && account) {
      setBehaviors([]);
      setAccount({
        ...account!,
        currentPatient_id: currentPatient._id!,
      });
      fetchBehaviors();
      if (account.role === "professionist") {
        fetchAccounts();
      }
    }
    if (!currentPatient) {
      setShowPatientsModal(true);
    }
  }, [currentPatient]);

  function logout() {
    if (window.confirm("Vuoi disconnettere l'account?")) {
      resetAppContext();
      localStorage.removeItem("accessToken");
      window.location.href = "/login";
    }
  }

  if (isLoading) {
    return (
      <div style={{ height: "100vh" }}>
        <MainLoading></MainLoading>
      </div>
    );
  }

  return (
    <AppContext.Provider
      value={{
        resetAppContext: resetAppContext,
        setIsLoading: setIsLoading,
        isLogged: isLogged,
        setIsLogged: setIsLogged,
        account: account,
        setAccount: setAccount,
        accounts: accounts,
        setAccounts: setAccounts,
        patients: patients,
        fetchPatients: fetchPatients,
        currentPatient: currentPatient,
        setCurrentPatient: setCurrentPatient,
        showPatientsModal: showPatientsModal,
        setShowPatientsModal: setShowPatientsModal,
        logout: logout,
      }}
    >
      <BehaviorsContext.Provider
        value={{
          activeRecords: behaviorActiveRecords,
          setActiveRecords: (_: any) => {
            localStorage.setItem("behaviorActiveRecords", JSON.stringify(_));
            setBehaviorActiveRecords(_);
          },
          behaviors: behaviors,
          fetchBehaviors: fetchBehaviors,
        }}
      >
        <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerStyle={{
            zIndex: "999999999999999999999999",
            marginTop: "78px",
          }}
          toastOptions={{
            className: "",
            duration: 5000,
            style: {
              background: "#fff",
              color: "#0c0d48",
            },
          }}
        />

        <GlobalStyle />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Behaviors />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/signup-success" element={<SignupSuccess />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/account" element={<Account />} />
            <Route path="/patients" element={<Patients />} />
            <Route
              path="/patients/first"
              element={<UpsertPatient isTheFirstPatient={true} />}
            />
            <Route path="/patients/new" element={<UpsertPatient />} />
            <Route path="/patients/:id" element={<UpsertPatient />} />
            <Route path="/behaviors" element={<Behaviors />} />
            <Route path="/behaviors/new" element={<UpsertBehavior />} />
            <Route path="/behaviors/edit" element={<UpsertBehavior />} />
            <Route path="/behaviors/records" element={<BehaviorHistory />} />
            <Route
              path="/behaviors/records/new"
              element={<UpsertBehaviorRecord />}
            />
            <Route
              path="/behaviors/records/edit"
              element={<UpsertBehaviorRecord />}
            />
            <Route path="/behaviors/reports" element={<BehaviorReports />} />
          </Routes>
        </BrowserRouter>
      </BehaviorsContext.Provider>
    </AppContext.Provider>
  );
}

export default App;
