import { createContext } from "react";
import { AccountModel } from "../models/AccountModel";
import { PatientModel } from "../models/PatientModel";

export interface AppContextInterface {
  resetAppContext: Function;
  setIsLoading: Function;
  isLogged: boolean;
  setIsLogged: Function;
  account?: AccountModel;
  setAccount: Function;
  accounts: AccountModel[];
  setAccounts: Function;
  patients: PatientModel[];
  fetchPatients: Function;
  currentPatient?: PatientModel;
  setCurrentPatient: Function;
  showPatientsModal: boolean;
  setShowPatientsModal: Function;
  logout: Function;
}

export const AppContext = createContext<AppContextInterface>(
  {} as AppContextInterface
);
