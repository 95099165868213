import moment from "moment";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import BehaviorCard from "../components/BehaviorCard";
import Box from "../components/Box";
import Button from "../components/form/Button";
import DurationInput, { IDuration } from "../components/form/DurationInput";
import Heading from "../components/form/Heading";
import Input from "../components/form/Input";
import InputLabel from "../components/form/InputLabel";
import InputRangeBtn from "../components/form/InputRangeBtn";
import SimpleIconButton from "../components/SimpleIconButton";
import { AppContext } from "../contexts/AppContext";
import { BehaviorsContext } from "../contexts/BehaviorsContext";
import MainLayout from "../layouts/MainLayout";
import { BehaviorModel } from "../models/BehaviorModel";
import { BehaviorRecordModel } from "../models/BehaviorRecordModel";
import Api from "../services/Api";

interface IState {
  behavior: BehaviorModel;
  record: BehaviorRecordModel;
  newRecord: BehaviorRecordModel & {
    activeRecordIndex: number;
  };
}

export default function UpsertBehaviorRecord() {
  const { state } = useLocation();
  const { behavior, record, newRecord }: IState = state;
  const { currentPatient } = useContext(AppContext);
  const { fetchBehaviors, activeRecords, setActiveRecords } =
    useContext(BehaviorsContext);
  const isNew = record ? false : true;
  const [pending, setPending] = useState<boolean>(false);

  const now = moment();

  const [date, setDate] = useState<Date>(now.toDate());
  const [time, setTime] = useState<string>(now.format("HH:mm"));
  const [duration, setDuration] = useState<IDuration>({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [intensity, setIntensity] = useState<number>(6);
  const navigate = useNavigate();

  function formatAndSetDuration(durationInSeconds: number) {
    const duration = moment.duration(durationInSeconds, "seconds");
    setDuration({
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
    });
  }

  function convertDurationToSeconds(duration: IDuration) {
    return (
      moment.duration(duration.hours, "hours").asSeconds() +
      moment.duration(duration.minutes, "minutes").asSeconds() +
      duration.seconds
    );
  }

  useEffect(() => {
    if (record) {
      setDate(moment(record.datetime).toDate());
      setTime(moment(record.datetime).format("HH:mm"));
      formatAndSetDuration(record.duration);
      setIntensity(record.intensity);
    }
  }, [record]);

  useEffect(() => {
    if (newRecord) {
      setDate(moment(newRecord.datetime).toDate());
      setTime(moment(newRecord.datetime).format("HH:mm"));
      formatAndSetDuration(newRecord.duration);
    }
  }, [newRecord]);

  function clearThisActiveRecord() {
    // Remove the active record from local storage
    if (newRecord) {
      setActiveRecords([
        ...activeRecords.slice(0, newRecord.activeRecordIndex), // Splice is not recommended when working with state in React
        ...activeRecords.slice(newRecord.activeRecordIndex + 1),
      ]);
    }
  }

  function getDatetime(): Date {
    const formattedDate = moment(date, "YYYY-MM-DD");
    const formattedTime = moment(time, "HH:mm");

    return formattedDate
      .set({
        hour: formattedTime.get("hour"),
        minute: formattedTime.get("minute"),
        second: formattedTime.get("second"),
      })
      .toDate();
  }

  async function createBehaviorRecord() {
    if (!currentPatient) return;
    setPending(true);
    const isCreated = await Api.createBehaviorRecord({
      behavior_id: behavior._id,
      patient_id: currentPatient._id!,
      datetime: getDatetime(),
      duration: convertDurationToSeconds(duration!),
      intensity: intensity,
    });
    if (isCreated === true) {
      clearThisActiveRecord();
      fetchBehaviors();
      toast.success("Registrato!");
      navigate(-1);
    }
    setPending(false);
  }

  async function cancelBehaviorRecord() {
    setPending(true);
    if (window.confirm("Vuoi annullare questa rilevazione?")) {
      clearThisActiveRecord();
      navigate(-1);
    }
    setPending(false);
  }

  async function updateBehaviorRecord() {
    if (!currentPatient) return;
    setPending(true);
    const isUpdated = await Api.updateBehaviorRecord({
      _id: record._id,
      behavior_id: record.behavior_id,
      patient_id: currentPatient._id!,
      datetime: getDatetime(),
      duration: convertDurationToSeconds(duration!),
      intensity: intensity,
    });
    if (isUpdated === true) {
      fetchBehaviors();
      toast.success("Aggiornato!");
      navigate(-1);
    }
    setPending(false);
  }

  async function deleteBehaviorRecord() {
    setPending(true);
    if (window.confirm("Vuoi eliminare questa rilevazione?")) {
      const isDeleted = await Api.deleteBehaviorRecord(record._id);
      if (isDeleted === true) {
        toast.success("Eliminato!");
        navigate(-1);
      }
    }
    setPending(false);
  }

  return (
    <>
      <MainLayout
        title={isNew ? "Monitora" : moment(record.datetime).format("LL")}
      >
        <Box>
          <div className="d-flex justify-content-between">
            <h4>{behavior.name}</h4>
            {!isNew ? (
              <SimpleIconButton
                onClick={() => {
                  if (!pending) {
                    deleteBehaviorRecord();
                  }
                }}
              >
                <i
                  className="fa-solid fa-trash"
                  style={{ color: "#dc0000" }}
                ></i>
              </SimpleIconButton>
            ) : (
              <></>
            )}
          </div>
          <div className="row gy-2 gx-2">
            <div className="col-7">
              <Input
                label="Data"
                type="date"
                value={moment(date).format("yyyy-MM-DD")}
                placeholder=""
                onChange={(e) => {
                  setDate(moment(e.currentTarget.value).toDate());
                }}
              ></Input>
            </div>
            <div className="col-5">
              <Input
                label="Ora"
                type="time"
                value={time}
                placeholder=""
                onChange={(e) => {
                  setTime(e.currentTarget.value);
                }}
              ></Input>
            </div>
            {behavior.trackDuration || record?.duration ? (
              <div className="col-12">
                <InputLabel>Durata</InputLabel>
                <DurationInput
                  value={duration}
                  onChange={(duration) => {
                    setDuration(duration);
                  }}
                ></DurationInput>
              </div>
            ) : (
              <></>
            )}
            {behavior.trackIntensity || record?.intensity ? (
              <div className="col-12">
                <InputRangeBtn
                  label="Intensità"
                  value={intensity}
                  onChange={(value) => setIntensity(value)}
                ></InputRangeBtn>
              </div>
            ) : (
              <></>
            )}
            <div className="col-12 mt-4">
              {isNew ? (
                <div className="row gx-1">
                  <div className="col-6">
                    <Button
                      loading={pending}
                      fullWidth
                      onClick={() => {
                        cancelBehaviorRecord();
                      }}
                      variant="light"
                    >
                      Annulla
                    </Button>
                  </div>
                  <div className="col-6">
                    <Button
                      loading={pending}
                      fullWidth
                      onClick={() => {
                        createBehaviorRecord();
                      }}
                    >
                      Fatto
                    </Button>
                  </div>
                </div>
              ) : (
                <Button
                  loading={pending}
                  fullWidth
                  onClick={() => {
                    updateBehaviorRecord();
                  }}
                >
                  Aggiorna
                </Button>
              )}
            </div>
          </div>
        </Box>
      </MainLayout>
    </>
  );
}
