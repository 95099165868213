import moment from "moment";
import React, { useContext } from "react";
import { toast, Toaster } from "react-hot-toast";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AppContext } from "../contexts/AppContext";
import PatientsModal from "./PatientsModal";
import SimpleIconButton from "./SimpleIconButton";
import Wrapper from "./Wrapper";

interface HeaderProps {
  title?: string;
  onBack?: Function;
  simple?: boolean;
}

const HeaderStyled = styled.div`
  background-color: #0c0d48;
  color: #fff;
  padding: 16px 0px;
  position: sticky;
  top: 0px;
  z-index: 99999;

  img {
    max-width: 100%;
    max-height: 34px;
  }

  h1 {
    font-size: 20px;
  }

  a {
    color: #fff;
  }
`;

const AccountIconStyled = styled.span`
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff21;
  border-radius: 100px;
`;

const PatientsModalTiggerStyled = styled.div`
  background: #14c3bc;
  height: 45px;
  width: 45px;
  border-radius: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  cursor: pointer;

  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.8px;
`;

const ArrowBackStyled = styled.span`
  background: #ffffff21;
  height: 45px;
  width: 45px;
  border-radius: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  font-size: 20px;
`;

export default function Header({ title, onBack, simple }: HeaderProps) {
  const { currentPatient, setShowPatientsModal, account, logout } =
    useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <HeaderStyled>
        <Wrapper>
          <div className="d-flex align-items-center">
            {simple ? (
              <>
                <img src="/logo-behavior.png" alt="logo" />
              </>
            ) : (
              <>
                {title ? (
                  <>
                    <ArrowBackStyled
                      onClick={() => {
                        navigate(-1);
                        onBack && onBack();
                      }}
                    >
                      <i className="fa-solid fa-arrow-left"></i>
                    </ArrowBackStyled>
                    <b className="ms-2" style={{ fontSize: "20px" }}>
                      {title}
                    </b>
                  </>
                ) : (
                  <NavLink to="/">
                    <img src="/logo-behavior.png" alt="logo" />
                  </NavLink>
                )}
                <div className="flex-grow-1"></div>
                {account?.role !== "professionist" && currentPatient ? (
                  <>
                    <PatientsModalTiggerStyled
                      onClick={() => {
                        setShowPatientsModal(true);
                      }}
                      className="me-2"
                    >
                      {currentPatient?.firstName.substring(0, 3)}
                    </PatientsModalTiggerStyled>
                    <NavLink to="/account">
                      <AccountIconStyled>
                        <i className="fa-solid fa-user"></i>
                      </AccountIconStyled>
                    </NavLink>
                  </>
                ) : (
                  <SimpleIconButton
                    onClick={() => {
                      logout();
                    }}
                  >
                    <i className="icofont-logout ms-2"></i>
                  </SimpleIconButton>
                )}
              </>
            )}
          </div>
        </Wrapper>
      </HeaderStyled>

      <PatientsModal></PatientsModal>
    </>
  );
}
