import styled from "styled-components";

interface ButtonProps {
  label: string;
  value: string;
}

interface MultipleButtonsProps {
  buttons: ButtonProps[];
  value: string;
  onChange: (value: string) => void;
}

const MBContainer = styled.div`
  border-radius: 6px;
  overflow: hidden;
  display: flex;

  button {
    height: 40px;
    background-color: transparent;
    border: none;
    flex: 1;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    color: inherit;
    background-color: #f1f3f9;
    opacity: 0.4;

    &.active {
      opacity: 1;
    }
  }
`;

const MultipleButtons = ({
  buttons,
  value,
  onChange,
}: MultipleButtonsProps) => {
  return (
    <>
      <MBContainer>
        {buttons.map((button) => (
          <button
            key={button.value}
            className={button.value === value ? "active" : ""}
            onClick={() => {
              onChange(button.value);
            }}
          >
            {button.label}
          </button>
        ))}
      </MBContainer>
    </>
  );
};

export default MultipleButtons;
