import { AccountModel } from "../models/AccountModel";
import { BehaviorModel } from "../models/BehaviorModel";
import { BehaviorRecordModel } from "../models/BehaviorRecordModel";
import { PatientModel } from "../models/PatientModel";
import http from "./HttpCommon";

function getAuthHeader() {
  return {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  };
}

http.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error: any) => {
    if (error && error.response && error.response.status) {
      if (
        error.response.status === 401 &&
        window.location.href.indexOf("/login") === -1 &&
        window.location.href.indexOf("/signup") === -1 &&
        window.location.href.indexOf("/signup-success") === -1 &&
        window.location.href.indexOf("/reset-password") === -1
      ) {
        window.location.href = "/login";
      }
    } else {
      alert("Spiacenti si è verificato un problema di connessione al server.");
    }
    return error;
  }
);

class Api {
  async signup(
    firstName: string,
    email: string,
    password: string,
    sex: string,
    birthday: string,
    category: string,
    purposes: number[]
  ): Promise<AccountModel | undefined> {
    try {
      const body = {
        firstName: firstName,
        email: email,
        password: password,
        sex: sex,
        birthday: birthday,
        category: category,
        purposes: purposes,
      };
      const response = await http.post("/signup", body);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }

  async confirmSignup(signupToken: string): Promise<boolean | undefined> {
    try {
      const body = {
        signupToken: signupToken,
      };
      const response = await http.post("/signup/confirm", body);
      return response.status === 204 ? true : false;
    } catch (err) {
      console.log(err);
    }
  }

  async resetPassword(
    resetPasswordToken: string,
    newPassword: string
  ): Promise<boolean | undefined> {
    try {
      const body = {
        resetPasswordToken: resetPasswordToken,
        newPassword: newPassword,
      };
      const response = await http.post("/reset-password", body);
      return response.status === 204 ? true : false;
    } catch (err) {
      console.log(err);
    }
  }

  async forgotPassword(email: string): Promise<boolean | undefined> {
    try {
      const body = {
        email: email,
      };
      const response = await http.post("/forgot-password", body);
      return response.status === 204 ? true : false;
    } catch (err) {
      console.log(err);
    }
  }

  async login(
    email: string,
    password: string
  ): Promise<AccountModel | undefined> {
    try {
      const body = {
        email: email,
        password: password,
      };
      const response = await http.post("/login", body);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }

  async loginByToken(betrackerOtp: string): Promise<AccountModel | undefined> {
    try {
      const body = {
        betrackerOtp: betrackerOtp,
      };
      const response = await http.post("/be-tracker/access-token", body);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }

  async getAccount(): Promise<AccountModel | undefined> {
    try {
      const response = await http.get("/account", {
        headers: {
          ...getAuthHeader(),
        },
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }

  async getAccounts(): Promise<AccountModel[] | undefined> {
    try {
      const response = await http.get("/patients/1/accounts", {
        headers: {
          ...getAuthHeader(),
        },
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }

  async setCurrentPatientId(patient_id: string): Promise<boolean | undefined> {
    try {
      const body = {
        currentPatient_id: patient_id,
      };
      const response = await http.patch("/account", body, {
        headers: {
          ...getAuthHeader(),
        },
      });
      return response.data ? true : false;
    } catch (err) {
      console.log(err);
    }
  }

  async getPatients(): Promise<PatientModel[] | undefined> {
    try {
      const response = await http.get("/patients", {
        headers: {
          ...getAuthHeader(),
        },
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }

  async createPatient(
    patient: PatientModel
  ): Promise<PatientModel | undefined> {
    try {
      const body = patient;
      const response = await http.post("/patients", body, {
        headers: {
          ...getAuthHeader(),
        },
      });
      if (response.status === 201) {
        return response.data;
      }
    } catch (err) {
      console.log(err);
    }
  }

  async updatePatient(patient: PatientModel): Promise<boolean | undefined> {
    try {
      const body = patient;
      const response = await http.patch(`/patients/${patient._id}`, body, {
        headers: {
          ...getAuthHeader(),
        },
      });
      return response.status === 200;
    } catch (err) {
      console.log(err);
    }
  }

  async deletePatient(patient_id: string): Promise<boolean | undefined> {
    try {
      const response = await http.delete(`/patients/${patient_id}`, {
        headers: {
          ...getAuthHeader(),
        },
      });
      return response.status === 200;
    } catch (err) {
      console.log(err);
    }
  }

  async getBehaviors(): Promise<BehaviorModel[] | undefined> {
    const params = {};
    try {
      const response = await http.get("/behavior-module/behaviors", {
        params: params,
        headers: {
          ...getAuthHeader(),
        },
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }

  async createBehavior(
    behavior: Omit<BehaviorModel, "_id">
  ): Promise<boolean | undefined> {
    try {
      const body = behavior;
      const response = await http.post("/behavior-module/behaviors", body, {
        headers: {
          ...getAuthHeader(),
        },
      });
      return response.status === 201;
    } catch (err) {
      console.log(err);
    }
  }

  async updateBehavior(behavior: BehaviorModel): Promise<boolean | undefined> {
    try {
      const body = behavior;
      const response = await http.patch(
        `/behavior-module/behaviors/${behavior._id}`,
        body,
        {
          headers: {
            ...getAuthHeader(),
          },
        }
      );
      return response.status === 200;
    } catch (err) {
      console.log(err);
    }
  }

  async deleteBehavior(behavior_id: string): Promise<boolean | undefined> {
    try {
      const response = await http.delete(
        `/behavior-module/behaviors/${behavior_id}`,
        {
          headers: {
            ...getAuthHeader(),
          },
        }
      );
      return response.status === 200;
    } catch (err) {
      console.log(err);
    }
  }

  async getBehaviorRecords(
    behavior_id?: string,
    account_id?: string
  ): Promise<BehaviorRecordModel[] | undefined> {
    const params = {
      behavior_id: behavior_id,
      account_id: account_id,
    };
    try {
      const response = await http.get("/behavior-module/records", {
        params: params,
        headers: {
          ...getAuthHeader(),
        },
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }

  async createBehaviorRecord(
    behaviorRecord: Omit<BehaviorRecordModel, "_id">
  ): Promise<boolean | undefined> {
    try {
      const body = behaviorRecord;
      const response = await http.post("/behavior-module/records", body, {
        headers: {
          ...getAuthHeader(),
        },
      });
      return response.status === 201;
    } catch (err) {
      console.log(err);
    }
  }

  async updateBehaviorRecord(
    behaviorRecord: BehaviorRecordModel
  ): Promise<boolean | undefined> {
    try {
      const body = behaviorRecord;
      const response = await http.patch(
        `/behavior-module/records/${behaviorRecord._id}`,
        body,
        {
          headers: {
            ...getAuthHeader(),
          },
        }
      );
      return response.status === 200;
    } catch (err) {
      console.log(err);
    }
  }

  async deleteBehaviorRecord(
    behaviorRecord_id: string
  ): Promise<boolean | undefined> {
    try {
      const response = await http.delete(
        `/behavior-module/records/${behaviorRecord_id}`,
        {
          headers: {
            ...getAuthHeader(),
          },
        }
      );
      return response.status === 200;
    } catch (err) {
      console.log(err);
    }
  }
}

export default new Api();
