import React, { useContext } from "react";
import styled from "styled-components";
import Header from "../components/Header";
import MainLoading from "../components/MainLoading";
import Wrapper from "../components/Wrapper";
import { AppContext } from "../contexts/AppContext";

interface MainLayoutProps {
  isLoading?: boolean;
  children: any;
  title?: string;
  onBack?: Function;
  simpleHeader?: boolean;
}

const TitleArea = styled.div`
  background-color: #040435;
  color: #fff;
  margin-bottom: -25px;
  padding-bottom: 25px;
  //height: 100px;
  /* display: flex;
  align-items: flex-end; */
`;

const MainLayout = ({
  isLoading,
  children,
  title,
  onBack,
  simpleHeader,
}: MainLayoutProps) => {
  return (
    <>
      <Header title={title} onBack={onBack} simple={simpleHeader} />
      {/* {title && (
        <TitleArea>
          <Wrapper>
            <h2 className="py-3">{title}</h2>
          </Wrapper>
        </TitleArea>
      )} */}
      <Wrapper className="mt-3">
        <div className="main-content">
          {isLoading ? <MainLoading></MainLoading> : children}
        </div>
      </Wrapper>
    </>
  );
};

export default MainLayout;
