import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color:#F1F3F9;
    color: #0c0d48;
  }

  .text {
    color: #7C8DB5;
  }

  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
    font-weight: 600;
  }

  .basic-table {
    margin: -16px;
    width: calc(100% + 16px * 2);

    @media screen and (max-width: 600px) {
      font-size: 14px;
    }

    thead {
      th {
        text-transform: uppercase;
        padding: 10px 5px;
        font-size: 12px;
        text-align: left;
      }

      input,
      select {
        margin-top: 10px;
      }
    }
    tbody {
      tr {
        background-color: #fff;
        cursor: pointer;
        //transition:  background-color 0.3s,  box-shadow 0.3s;

        td {
          padding: 16px 5px;
          font-weight: 500;
        }

        &:nth-child(odd) {
          background-color: #f8f9fc;
        }

        &:hover {
          box-shadow: 0px 0px 0px 4px #14c3bc4d;
          outline: none;
          //border: 1px solid #14c3bc4d;
          z-index: 11;
          position: relative;
          background-color: #e7f8f6 !important;
        }
      }
    }

  }
`;

export default GlobalStyle;
