import React, { ChangeEvent } from "react";
import styled from "styled-components";
import Input from "./Input";
import InputLabel from "./InputLabel";

export interface IDuration {
  hours: number;
  minutes: number;
  seconds: number;
}

interface DurationInputProps {
  value: IDuration;
  onChange: (duration: IDuration) => void;
  disable?: boolean;
}

const DurationInputStyle = styled.div``;

const DurationInput = ({
  value,
  onChange,
  disable = false,
}: DurationInputProps) => {
  return (
    <DurationInputStyle>
      <div className="d-flex align-items-center g-2">
        <div className="flex-grow-1">
          <Input
            placeholder="00"
            type="number"
            value={value?.hours}
            onChange={(e) => {
              onChange({
                ...value,
                hours: Number(e.currentTarget.value),
              });
            }}
            disable={disable}
            selectOnFocus={true}
          />
        </div>
        <span className="mx-2">:</span>
        <div className="flex-grow-1">
          <Input
            placeholder="00"
            type="number"
            value={value?.minutes}
            onChange={(e) => {
              onChange({
                ...value,
                minutes: Number(e.currentTarget.value),
              });
            }}
            disable={disable}
            selectOnFocus={true}
          />
        </div>
        <span className="mx-2">:</span>
        <div className="flex-grow-1">
          <Input
            placeholder="00"
            type="number"
            value={value?.seconds}
            onChange={(e) => {
              onChange({
                ...value,
                seconds: Number(e.currentTarget.value),
              });
            }}
            disable={disable}
            selectOnFocus={true}
          />
        </div>
      </div>
    </DurationInputStyle>
  );
};

export default DurationInput;
