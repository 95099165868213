import React, { ChangeEvent } from "react";
import styled from "styled-components";
import InputLabel from "./InputLabel";
interface CheckboxProps {
  children?: any;
  label?: string;
  checked: boolean;
  onChange: (value: boolean) => void;
  selectOnFocus?: boolean;
  disable?: boolean;
}

const CheckboxWrapperStyle = styled.span`
  position: relative;

  label {
    user-select: none;
  }

  input {
    opacity: 0;
    height: 0px;
    width: 0px;
    position: absolute;
  }

  & > span {
    display: grid;
    grid-template-columns: min-content auto;
  }
`;

const CheckboxStyle = styled.span`
  font-size: 24px;
  color: #14c3bc;
  cursor: pointer;
  display: inline-block;
  margin-right: 6px;
  vertical-align: middle;
  margin-top: -5px;

  &:focus {
  }

  &:disabled {
    border-color: transparent;
  }

  label {
    user-select: none;
  }
`;

const Checkbox = ({
  label,
  checked,
  onChange,
  selectOnFocus,
  disable = false,
  children,
}: CheckboxProps) => {
  return (
    <CheckboxWrapperStyle>
      {label && <InputLabel className="text">{label}</InputLabel>}
      <input
        type="checkbox"
        checked={checked}
        onFocus={(e) => {
          if (selectOnFocus) e.currentTarget.select();
        }}
        disabled={disable}
      />

      <span
        onClick={() => {
          onChange(!checked);
        }}
      >
        <CheckboxStyle>
          {checked ? (
            <i
              className="fa-solid fa-square-check"
              style={{ color: "#14c3bc" }}
            />
          ) : (
            <i className="fa-regular fa-square" style={{ color: "#e1e3ea" }} />
          )}
        </CheckboxStyle>
        <label>{children}</label>
      </span>
    </CheckboxWrapperStyle>
  );
};

export default Checkbox;
