import { BehaviorRecordModel } from "./BehaviorRecordModel";

export const behaviorColorList = [
  "#00C7F8",
  "#FFB400",
  "#FF3285",
  "#B300F8",
  "#0066F8",
  "#F23900",
  "#1CC100",
];

export type BehaviorModel = {
  _id: string;
  patient_id: string;
  name: string;
  color: string;
  trackDuration: boolean;
  trackIntensity: boolean;
  account_ids?: string[];
  lastRecord?: BehaviorRecordModel;
};
