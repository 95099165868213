import moment from "moment";
import React, { useContext, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AppContext } from "../contexts/AppContext";
import { BehaviorsContext } from "../contexts/BehaviorsContext";
import { BehaviorModel } from "../models/BehaviorModel";
import { PatientModel } from "../models/PatientModel";
import Api from "../services/Api";
import Button from "./form/Button";
import SimpleIconButton from "./SimpleIconButton";
import Wrapper from "./Wrapper";

interface PatientsModalProps {}

const PatientsModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000d1;
  height: 100vh;
  width: 100vw;
  z-index: 99999999999999;
  padding: 16px;
  box-sizing: border-box;

  .patients-modal {
    background-color: white;
    border-radius: 6px;
    padding: 16px;
    max-width: 450px;
    margin: 0 auto;
    margin-bottom: 10px;

    & > ul {
      list-style: none;
      padding: 0;
      margin: 0;
      cursor: pointer;

      li {
        padding: 10px 0px;
        margin: 0px 0px;
        border-bottom: 2px solid #f1f3f9;
        display: flex;
        align-items: center;
        font-weight: 500;

        &:last-child {
          border-bottom: none;
        }

        .circle-avatar {
          border-radius: 100%;
          height: 45px;
          width: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #f1f3f9;
          background-color: #0c0d48;
          color: #fff;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 600;
          margin-right: 10px;
          letter-spacing: 0.8px;
        }

        &.active {
          .circle-avatar {
            background-color: #14c3bc;
            color: #fff;
          }
        }

        &:hover {
          background-color: #f1f3f9;

          i {
            background-color: #fff;
          }
        }
      }
    }

    animation-name: popOut;
    animation-duration: 0.2s;

    @keyframes popOut {
      0% {
        transform: scale(0.95);
      }
      100% {
        transform: scale(1);
      }
    }
  }
`;

const PatientsModal = ({}: PatientsModalProps) => {
  const {
    account,
    patients,
    currentPatient,
    setCurrentPatient,
    setIsLoading,
    showPatientsModal,
    setShowPatientsModal,
  } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!patients.length) {
      navigate("/patients/first");
      setShowPatientsModal(false);
    }
  }, [patients]);

  async function selectCurrentPatient(patient: PatientModel) {
    setIsLoading(true);
    await Api.setCurrentPatientId(patient._id!);
    setIsLoading(false);
    setCurrentPatient(patient);
    setShowPatientsModal(false);
    navigate("/");
  }

  function closePatientsModal() {
    setShowPatientsModal(false);
  }

  if (!showPatientsModal) {
    return <></>;
  }

  return (
    <PatientsModalOverlay
      style={{ backgroundColor: currentPatient ? undefined : "#0c0d48" }}
    >
      <div className="patients-modal">
        <h5 className="d-flex align-items-center justify-content-between">
          Seleziona soggetto
          {currentPatient ? (
            <SimpleIconButton
              onClick={() => {
                closePatientsModal();
              }}
            >
              <i className="icofont-close"></i>
            </SimpleIconButton>
          ) : (
            <></>
          )}
        </h5>
        <span></span>
        <ul>
          {patients.map((patient) => (
            <li
              onClick={() => selectCurrentPatient(patient)}
              key={patient._id}
              className={
                currentPatient && account?.currentPatient_id === patient._id
                  ? "active"
                  : ""
              }
            >
              <div className="circle-avatar">
                {patient?.firstName.substring(0, 3)}
              </div>
              {/* {patient.sex === "male" ? (
                <i className="icofont-user-male"></i>
              ) : (
                <i className="icofont-user-female"></i>
              )} */}

              {patient.firstName}
            </li>
          ))}
        </ul>
        <Button
          className="mt-1"
          fullWidth
          variant="light"
          onClick={() => {
            closePatientsModal();
            navigate("/patients/new");
          }}
        >
          Nuovo soggetto
        </Button>
        {/* <Button
          className="mt-3"
          variant="light"
          fullWidth
          onClick={() => {
            navigate("/patients");
          }}
        >
          Gestisci soggetti
        </Button> */}
      </div>
    </PatientsModalOverlay>
  );
};

export default PatientsModal;
