import moment from "moment";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Box from "../components/Box";
import Button from "../components/form/Button";
import Heading from "../components/form/Heading";
import Input from "../components/form/Input";
import InputLabel from "../components/form/InputLabel";
import { AppContext } from "../contexts/AppContext";
import MainLayout from "../layouts/MainLayout";
import { PatientModel } from "../models/PatientModel";
import Api from "../services/Api";
import { mockData } from "../services/mockDataChart";
import { calculateAge, renderSexIcon } from "../services/utils";

export default function Patients() {
  const navigate = useNavigate();
  const { patients, setShowPatientsModal } = useContext(AppContext);

  useEffect(() => {
    setShowPatientsModal(false);
  }, []);

  return (
    <>
      <MainLayout title="Soggetti">
        <Button
          className="mb-3"
          fullWidth
          variant="light"
          onClick={() => {
            navigate("/patients/new");
          }}
        >
          Nuovo soggetto
        </Button>
        <Box>
          <table className="basic-table text-center">
            <thead>
              <tr>
                <th className="text-center">
                  <InputLabel>Nome</InputLabel>
                </th>
                <th className="text-center">
                  <InputLabel>Sesso</InputLabel>
                </th>
                <th className="text-center">
                  <InputLabel>Età</InputLabel>
                </th>
              </tr>
            </thead>
            <tbody>
              {patients.map((patient: PatientModel) => (
                <tr
                  key={patient._id}
                  onClick={() => {
                    navigate("/patients/edit", {
                      state: {
                        patient: patient,
                      },
                    });
                  }}
                >
                  <td>{patient.firstName}</td>
                  <td>{renderSexIcon(patient.sex)}</td>
                  <td>{calculateAge(patient.birthday)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </MainLayout>
    </>
  );
}
