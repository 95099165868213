import moment from "moment";
import React, { useContext, useState } from "react";
import { toast } from "react-hot-toast";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AppContext } from "../contexts/AppContext";
import { BehaviorsContext } from "../contexts/BehaviorsContext";
import { BehaviorModel } from "../models/BehaviorModel";
import { BehaviorRecordModel } from "../models/BehaviorRecordModel";
import Api from "../services/Api";
import Button from "./form/Button";
import SimpleIconButton from "./SimpleIconButton";

interface ButtonProps {
  behavior: BehaviorModel;
  now?: Date;
}

const BehaviorCardDiv = styled.div`
  background-color: white;
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 10px;
`;

const BehaviorCard = ({ behavior, now }: ButtonProps) => {
  const navigate = useNavigate();
  const { currentPatient } = useContext(AppContext);
  const { fetchBehaviors, activeRecords, setActiveRecords } =
    useContext(BehaviorsContext);
  const [pending, setPending] = useState<boolean>(false);

  const activeBehaviorRecord = activeRecords.find(
    (x) => x.behavior_id === behavior._id
  );

  function displayStopwatch() {
    const duration = moment.duration(
      moment(now).diff(activeBehaviorRecord.datetime)
    );
    return (
      duration.hours() + ":" + duration.minutes() + ":" + duration.seconds()
    );
  }

  async function createFastBehaviorRecord() {
    // Do not wait to save
    setPending(true);
    await new Promise((resolve) => setTimeout(resolve, 200));
    toast.success("Registrato!");
    setPending(false);
    const isCreated = await Api.createBehaviorRecord({
      behavior_id: behavior._id,
      patient_id: currentPatient!._id,
      datetime: new Date(), //moment(date + " " + time).toDate(), // todo DA VERIFICARE CHE LA DATA SIA CORRETTA
    } as BehaviorRecordModel);
    if (isCreated === true) {
      fetchBehaviors();
    } else {
      toast.error("Errore!");
    }
  }

  function displayDateOrMinutes(date: Date | undefined) {
    if (!date) return "Nessun inserimento";
    const now = moment();
    const minutesDiff = now.diff(date, "minutes");
    if (minutesDiff === 0) {
      return `Appena inserito`;
    }
    if (minutesDiff <= 60) {
      return `${minutesDiff} minuti fa`;
    } else {
      return moment(date).format("LLL");
    }
  }

  return (
    <BehaviorCardDiv>
      <div className="d-flex justify-content-between">
        <h6 className="mb-1">{behavior.name}</h6>
        <span
          onClick={() => {
            //alert("edit");
            navigate("/behaviors/edit", { state: { behavior: behavior } });
          }}
        >
          <SimpleIconButton style={{margin: "-8px -8px 0 0"}}>
            <i className="fa-solid fa-pen-to-square"></i>
          </SimpleIconButton>
        </span>
      </div>
      <span className="text d-block mb-2" style={{ fontSize: "14px" }}>
        <i
          className="fa-solid fa-clock-rotate-left me-1"
          style={{ fontSize: "12px" }}
        ></i>
        {displayDateOrMinutes(behavior.lastRecord?.datetime)}
      </span>

      {activeBehaviorRecord ? (
        <Button
          onClick={() => {
            const activeRecordIndex = activeRecords.findIndex(
              (x) => x.behavior_id === behavior._id
            );
            const activeRecord = activeRecords[activeRecordIndex];
            navigate("/behaviors/records/new", {
              state: {
                behavior: behavior,
                newRecord: {
                  datetime: activeRecord.datetime,
                  duration: moment(now).diff(activeRecord.datetime, "seconds"),
                  activeRecordIndex: activeRecordIndex,
                },
              },
            });
          }}
          fullWidth
          color={behavior.color}
          backgroundColor={behavior.color + "1f"}
          iconClass="fa-solid fa-circle-stop"
        >
          {displayStopwatch()}
        </Button>
      ) : (
        <div className="d-flex">
          <div className="me-2">
            <Button
              onClick={() => {
                navigate("/behaviors/records/new", {
                  state: {
                    behavior: behavior,
                  },
                });
              }}
              backgroundColor={behavior.color}
            >
              <i className="fa-solid fa-circle-plus"></i>
            </Button>
          </div>
          <div className="flex-fill">
            {!behavior.trackDuration && !behavior.trackIntensity ? (
              <Button
                loading={pending}
                onClick={() => {
                  createFastBehaviorRecord();
                }}
                fullWidth
                backgroundColor={behavior.color}
                iconClass="fa-solid fa-bolt"
              >
                Registra
              </Button>
            ) : (
              <Button
                onClick={() => {
                  setActiveRecords([
                    ...activeRecords,
                    {
                      behavior_id: behavior._id,
                      datetime: new Date(),
                    },
                  ]);
                }}
                fullWidth
                backgroundColor={behavior.color}
                iconClass="fa-solid fa-circle-play"
              >
                Registra
              </Button>
            )}
          </div>
        </div>
      )}
    </BehaviorCardDiv>
  );
};

export default BehaviorCard;
