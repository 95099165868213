import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Box from "../components/Box";
import Button from "../components/form/Button";
import Checkbox from "../components/form/Checkbox";
import Heading from "../components/form/Heading";
import Input from "../components/form/Input";
import InputLabel from "../components/form/InputLabel";
import Select from "../components/form/Select";
import Wrapper from "../components/Wrapper";
import { AppContext } from "../contexts/AppContext";
import { AccountModel } from "../models/AccountModel";
import Api from "../services/Api";
import { sexOptions } from "../services/GlobalVariables";

export default function Signup() {
  const { setAccount, setCurrentPatient, fetchPatients } =
    useContext(AppContext);
  const [firstName, setFirstName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [sex, setSex] = useState<string>("");
  const [birthday, setBirthday] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [purposes, setPurposes] = useState<number[]>([]);
  const [privacyChecked, setPrivacyChecked] = useState<boolean>(false);

  let navigate = useNavigate();
  const [pending, setPending] = useState<boolean>(false);

  const categoryOptions = [
    {
      value: "parent",
      label: "Genitore",
    },
    {
      value: "teacher",
      label: "Insegnante",
    },
    {
      value: "educator",
      label: "Educatore",
    },
    {
      value: "behaviorTechnician",
      label: "Tecnico del comportamento",
    },
    {
      value: "other",
      label: "Altro",
    },
  ];

  const purposeOptions = [
    {
      value: 1,
      label: "Desidero monitorare alcuni miei comportamenti",
    },
    {
      value: 2,
      label: "Desidero monitorare i comportamenti di un altro soggetto adulto",
    },
    {
      value: 3,
      label:
        "Desidero monitorare i comportamenti di un altro soggetto minorenne",
    },
    {
      value: 4,
      label: "Altro",
    },
  ];

  async function signup(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    try {
      if (!firstName) return toast.error("Inserisci il tuo nome");
      if (!email) return toast.error("Inserisci la tua email");
      if (!password) return toast.error("Inserisci una password");
      if (!purposes.length)
        return toast.error("Seleziona un motivo della tua registrazione");
      if (!privacyChecked)
        return toast.error("Seleziona la casella di accettazione");
      setPending(true);
      const account = await Api.signup(
        firstName,
        email,
        password,
        sex,
        birthday,
        category,
        purposes
      );
      if (account) {
        setAccount(account);
        localStorage.setItem("accessToken", account.token);
        navigate("/");
      } else {
        toast.error("Dati errati o mancanti");
      }
      setPending(false);
    } catch (err) {
      toast.error("Errore generico");
    }
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "#180c48",//"#0c0d48",
          minHeight: "100vh",
          width: "100vw",
          backgroundImage: `url("/images/watson-background-overlay.png")`,
          backgroundPosition:"bottom center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Wrapper style={{ maxWidth: "400px", paddingTop: "60px" }}>
          <img
            src="/logo-behavior-vertical.png"
            alt="logo"
            width="100%"
            style={{
              maxHeight: "90px",
              width: "auto",
              margin: "0 auto",
              display: "block",
            }}
          />
          <Box className="mt-3 p-4">
            <h3 className="mb-3">Registrati</h3>

            <form autoComplete="off" onSubmit={signup}>
              <div className="row gy-3">
                <div className="col-12">
                  <Input
                    label="Nome"
                    value={firstName}
                    type="text"
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                    required
                  ></Input>
                </div>
                <div className="col-12">
                  <Input
                    label="Email"
                    value={email}
                    type="email"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setEmail(e.target.value);
                    }}
                    required
                  ></Input>
                </div>
                <div className="col-12">
                  <Input
                    label="Inserisci una password"
                    placeholder="Password"
                    value={password}
                    type="password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    required
                  ></Input>
                </div>
                {/* <div className="col-12">
                <Select
                  label="Sesso"
                  placeholder="Seleziona"
                  options={sexOptions}
                  value={sex}
                  onChange={(e) => {
                    setSex(e.target.value);
                  }}
                ></Select>
              </div>
              <div className="col-12">
                <Input
                  label="Data di nascita"
                  value={birthday}
                  type="date"
                  onChange={(e) => {
                    setBirthday(e.target.value);
                  }}
                ></Input>
              </div> */}
                {password && (
                  <div className="col-12">
                    <InputLabel>Perchè ti stai registrando?</InputLabel>
                    <div>
                      {purposeOptions.map((purpose) => {
                        const ind = purposes.findIndex(
                          (v) => v === purpose.value
                        );
                        return (
                          <div>
                            <Checkbox
                              checked={ind !== -1}
                              onChange={() => {
                                if (ind !== -1) {
                                  const newPurposes = [...purposes];
                                  newPurposes.splice(ind, 1);
                                  setPurposes(newPurposes);
                                } else {
                                  setPurposes([...purposes, purpose.value]);
                                }
                              }}
                            >
                              {purpose.label}
                            </Checkbox>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {purposes.findIndex((p) => p >= 2) !== -1 ? (
                  <div className="col-12">
                    <Select
                      label="Ruolo"
                      placeholder="Seleziona"
                      options={categoryOptions}
                      value={category}
                      onChange={(e) => {
                        setCategory(e.target.value);
                      }}
                    ></Select>
                  </div>
                ) : (
                  <></>
                )}
                <hr
                  style={{
                    backgroundColor: "#f7f9fa",
                    height: "2px",
                    opacity: "1",
                  }}
                />
                <div className="col-12 mt-0">
                  <Checkbox
                    checked={privacyChecked}
                    onChange={() => {
                      setPrivacyChecked(!privacyChecked);
                    }}
                  >
                    Ho letto e accetto l'informativa sulla{" "}
                    <a
                      href="https://watsongest.com/privacy-policy/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      privacy policy
                    </a>
                  </Checkbox>
                </div>
                <div className="col-12">
                  <Button submit fullWidth loading={pending}>
                    Registrati
                  </Button>
                </div>

                <div className="col-12 text-center">
                  <NavLink
                    to="/login"
                    style={{
                      textDecoration: "none",
                      color: "#7b8db5",
                    }}
                  >
                    Hai già un account? Accedi qui.
                  </NavLink>
                </div>
              </div>
            </form>
          </Box>
        </Wrapper>
      </div>
    </>
  );
}
